import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

public isCheckEmail : boolean = false;

public forgetPassword : FormGroup;
  notifier: any;

  constructor(public fb : FormBuilder,
    public notifierService : NotifierService, public route : Router) {

    this.notifier = notifierService;

   }

  ngOnInit(): void {

this.forgetPassword = this.fb.group({
      emailForget : ["" , [Validators.required, Validators.email]]
});

  }





public getEmailData=(data)=>{
  console.log(data.value);
  data.reset();
  this.notifier.notify('info', 'Email Successfully Sent!');

}

public goToHome=()=>{
  this.route.navigate(['/home-cheetah']);
  console.log("Hello")
}




}
