import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { AppService } from '../app.service';
import { NgxSpinnerService } from "ngx-spinner";
import * as AOS from 'aos';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  public isSignUp: boolean = false;
  public loginForm: FormGroup;
  public signupForm: FormGroup;
  public notifier: NotifierService;
  responseMessage: any;
  Type: number;

  constructor(public fb: FormBuilder,
    public router: Router, public notifierService: NotifierService,
    public service: AppService, public spinner: NgxSpinnerService) {

    this.notifier = notifierService;

  }

  ngOnInit(): void {

    AOS.init({
      duration: 700,
    });

    this.loginForm = this.fb.group({
      emailSignIn: ['', [Validators.required, Validators.email]],
      passwordLogin: ['', [Validators.required]]
    });

    this.signupForm = this.fb.group({
      firstname: ['', [Validators.required, Validators.minLength, Validators.maxLength]],
      lastname: ['', [Validators.required, Validators.minLength, Validators.maxLength]],
      emailSignUp: ['', [Validators.required, Validators.email]],
      passwordSignUp: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
      category: ['', Validators.required]
    });


  }




  //Method for get Form Data
  public getDataSignUp = (data) => {
    console.log(data.value);
    this.signupForm.reset();
    this.notifier.notify('info', 'Successfully Submitted!');
  }

























  public getDataLogin = () => {

    this.spinner.show();
    let x = {
      email: this.loginForm.controls['emailSignIn'].value,
      password: this.loginForm.controls['passwordLogin'].value,
      password_confirmation: this.loginForm.controls['passwordLogin'].value
    };


    this.service.login(x).subscribe((res: any) => {

     // console.log(res);
      this.responseMessage = res.message;
      if (res.message == 'True') {
        let user = res.record;

        if (user.type === 3) {
          localStorage.setItem("tokenCatering", res.token);

          if (user.status === "inActive") {
            this.router.navigate(['add-catering']);
            this.spinner.hide();
          }
          else {
            setTimeout(() => {

              this.router.navigate(['admin-catering']);
              this.getSingleProfile();
              this.spinner.hide();

            }, 2000);


          }
        }
        else if (user.type === 4) {
          localStorage.setItem("tokenRestaurant", res.token);
          if (user.status === "inActive") {
            this.router.navigate(['add-restaurant']);
            this.spinner.hide();
          }
          else {
            setTimeout(() => {
              this.router.navigate(['admin-restaurant']);
              this.getSingleRestaurantProfile();
              this.spinner.hide();
            }, 2000);
          }
        }
        else if (user.type === 5) {
          localStorage.setItem("tokenUser", res.token);
          this.router.navigate(['home-cheetah']);
        }
        else {
          this.notifier.notify("error", "User not Exist!");
          this.spinner.hide();
        }

        localStorage.setItem('tokenCheetah', res.token);
        this.loginForm.reset();
        this.spinner.hide();
      }
      else {
        this.notifier.notify('error', this.responseMessage);
        this.spinner.hide();
      }
    });

  }
























  public getDataRegister = () => {
    if (this.signupForm.controls['category'].value === "1") {
      this.Type = 3;
    }
    else if (this.signupForm.controls['category'].value === '2') {
      this.Type = 4;
    }
    else if (this.signupForm.controls['category'].value === '3') {
      this.Type = 5;
    }
    else {
      return;
    }

    let x = {
      name: this.signupForm.controls['firstname'].value + this.signupForm.controls['lastname'].value,
      email: this.signupForm.controls['emailSignUp'].value,
      password: this.signupForm.controls['passwordSignUp'].value,
      password_confirmation: this.signupForm.controls['confirmPassword'].value,
      type: this.Type,
      category: this.signupForm.controls['category'].value,

    };

    if (this.signupForm.controls['passwordSignUp'].value === this.signupForm.controls['confirmPassword'].value) {
      // console.log(x);
      this.service.register(x).subscribe((res: any) => {

        if (res.message === 'True') {
          this.notifier.notify("info", "Successfully Submitted!");
          //   console.log(res);
          this.isSignUp = false;
          this.signupForm.reset();
        }
        else {
          this.notifier.notify("error", res.message);
        }

      });
    }
    else {
      this.notifier.notify("error", "Password and Confirm Password Not Match!");
    }



  }








  public getSingleProfile = () => {
    this.spinner.show();
    this.service.getsingleprofile().subscribe((res: any) => {

      if (res.status === "True") {
        if (res.Provider[0].approval_status === "pending" || (res.Provider[0].approval_status === "Approved")) {
          localStorage.setItem('cateringDashboard', res.Provider[0].approval_status);
          this.spinner.hide();
        }
        else {
          return;
        }

      }
    });
  }







  public getSingleRestaurantProfile = () => {
    this.spinner.show();
    this.service.getSingleRestaurantProfile().subscribe((res: any) => {

      if (res.status === "True") {
        if (res.Profile[0].admin_status === "pending" || (res.Profile[0].admin_status === "Approved")) {
          localStorage.setItem('restaurantDashboard', res.Profile[0].admin_status);
          this.spinner.hide();
        }
        else {
          return;
        }

      }
    });
  }












  //Show SignUp Or login Form Start
  public isSignUpForm = () => {
    if (this.isSignUp == true) {
      this.isSignUp = false;
    }

    else {
      this.isSignUp = true;
    }


  }
  //Show SignUp Or login Form End



  //go to Home Method
  public goToHome = () => {
    this.router.navigate(['/home-cheetah']);

  }


}
