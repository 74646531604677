
import { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { share } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class CartService {



  public totalCartItems = this.getItems();
  public items = [];
 



  constructor() { }





public removeAllCart=()=>{
    this.items  = [];
    localStorage.setItem("products" , JSON.stringify(this.items));
}

 

  addToCart(product) {
    this.items.push(product);
    localStorage.setItem("products", JSON.stringify(this.items));  
  }



  getItems() {

    if("products" in localStorage)
    {
      return JSON.parse(localStorage.getItem("products"));
    }
   
  }


  public getLengthItems = () => {

    if ("products" in localStorage)
    {
      var a = 0;
      a = JSON.parse(localStorage.getItem("products")).length;
      return a;
    }
    else
    {
      return;
    }

  }





}
