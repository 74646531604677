import { HttpInterceptor } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class TokeninterceptorService implements HttpInterceptor  {
  constructor(public injector : Injector) { }

  intercept(req , next) {
    let appService = this.injector.get(AppService);
     let tokenizedReq = req.clone({
       setHeaders : {
         Authorization : `Bearer ${appService.getToken()}`
       }
     })
     return next.handle(tokenizedReq);
  }
}
