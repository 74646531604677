import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-cheetay-footer',
  templateUrl: './cheetay-footer.component.html',
  styleUrls: ['./cheetay-footer.component.css']
})
export class CheetayFooterComponent implements OnInit {
  address: any;
  ContentsData: any;
  socialLinksData: any;
  facebookLink: any;
  instagramLink: any;
  linkedinLink: any;
  footerAddress: any;
  contactsData: any;
  footerPhone: any;

  constructor(public service : AppService, public router : Router) { }

  ngOnInit(): void {
    this.getContentWeb();
    this.getSocialLinks();
    this.getContactWeb();
  }

  public getContentWeb=()=>{

    this.service.getContentWeb().subscribe((res :any)=>{
      this.ContentsData = res.record;
      this.footerAddress =  this.ContentsData.filter(x => x.title === "Footer_Address");
       
    });

  }




  public getContactWeb=()=>{

    this.service.getContactWeb().subscribe((res :any)=>{
      this.contactsData = res.record;
      this.footerPhone =  this.contactsData.filter(x => x.title === "Footer_Phone");
      
    });

  }





  public getSocialLinks=()=>{
    this.service.getSocialLinksWeb().subscribe((res:any)=>{
          this.socialLinksData = res.record;
          this.facebookLink = this.socialLinksData[0].link;
          this.instagramLink = this.socialLinksData[1].link;
          this.linkedinLink = this.socialLinksData[2].link;
    });
 }




}
