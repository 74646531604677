<!-------------------------------------------------------------->
<!------------------Header Navbar Start------------------------>
<!--------------------------------------------------------------->
<!-- <app-cheetay-header></app-cheetay-header> -->
<!-------------------------------------------------------------->
<!------------------Header Navbar End------------------------>
<!--------------------------------------------------------------->



<!--------------------------------------------------------------->
<!------------------Main Login Page Start------------------------>
<!--------------------------------------------------------------->
<div class="container-fluid loginPageBg LeafBG">
  <notifier-container></notifier-container>
  <!---------------------------Sign up Form Start---------------------------------->
  <div *ngIf="isSignUp" data-aos="slide-down">
    <form class="signup" [formGroup]="signupForm" autocomplete="off">
      <span class="text-right " (click)="goToHome()"><i class="fas fa-times-circle closeSignUp"></i></span>
      <h1 class="title mt-3">Cheetah Signup</h1>
      <h2>Already have an account? <span (click)="isSignUpForm()">Login</span></h2>

      <div class="signup__field">
        <input class="signup__input" type="text" formControlName="firstname" required />
        <label class="signup__label" for="firstname">First Name</label>
      </div>
      <div class="alert alert-danger px-2 py-1"
        *ngIf="signupForm.get('firstname').touched && signupForm.get('firstname').invalid">
        <div *ngIf="signupForm.get('firstname').errors.required">First Name is required</div>
        <div *ngIf="signupForm.get('firstname').errors.minlength">First Name is too Short!</div>
        <div *ngIf="signupForm.get('firstname').errors.maxlength">First Name is too Long!</div>
      </div>
      <div class="signup__field">
        <input class="signup__input" type="text" formControlName="lastname" required />
        <label class="signup__label" for="lastname">Last Name</label>
      </div>

      <div class="signup__field">
        <input class="signup__input" formControlName="emailSignUp" type="email" required />
        <label class="signup__label">Email</label>
      </div>
      <div class="alert alert-danger px-2 py-1"
        *ngIf="signupForm.get('emailSignUp').touched && signupForm.get('emailSignUp').invalid">
        <div *ngIf="signupForm.get('emailSignUp').errors.required">Email is required</div>
        <div *ngIf="signupForm.get('emailSignUp').errors.minlength">Email is too Short!</div>
        <div *ngIf="signupForm.get('emailSignUp').errors.maxlength">Email is too Long!</div>
        <div *ngIf="signupForm.get('emailSignUp').errors.email">Email is not valid!</div>
      </div>

      <div class="signup__field">
        <input class="signup__input" formControlName="passwordSignUp" type="password" required />
        <label class="signup__label" for="password">Password</label>
      </div>
      <div class="alert alert-danger px-2 py-1"
        *ngIf="signupForm.get('passwordSignUp').touched && signupForm.get('passwordSignUp').invalid">
        <div *ngIf="signupForm.get('passwordSignUp').errors.required">Password is required</div>
        <div *ngIf="signupForm.get('passwordSignUp').errors.minlength">Password is too Short!</div>
        <div *ngIf="signupForm.get('passwordSignUp').errors.maxlength">Password is too Long!</div>
        <div *ngIf="signupForm.get('passwordSignUp').errors.email">Password is not valid!</div>
      </div>

      <div class="signup__field">
        <input class="signup__input" type="password" formControlName="confirmPassword" required />
        <label class="signup__label" for="cpassword">Confirm Password</label>
      </div>
      <div class="alert alert-danger px-2 py-1"
        *ngIf="signupForm.get('confirmPassword').touched && signupForm.get('confirmPassword').invalid">
        <div *ngIf="signupForm.get('confirmPassword').errors.required">Confirm Password is required</div>
        <div *ngIf="signupForm.get('confirmPassword').errors.minlength">Confirm Password is too Short!</div>
        <div *ngIf="signupForm.get('confirmPassword').errors.maxlength">Confirm Password is too Long!</div>
        <div *ngIf="signupForm.get('confirmPassword').errors.email">Confirm Password is not valid!</div>
      </div>
      <div class="signup__field">
        <select class="drop_down pb-2" formControlName="category" Placeholder="Select Category" required>
          <option disabled="true" [selected]="true">Choose Category</option>
          <option value="3">Cheetah User</option>
          <option value="2">Restaurant</option>
          <option value="1">Catering</option>
        </select>
        <div class="alert alert-danger px-2 py-1"
          *ngIf="signupForm.get('category').touched && signupForm.get('category').invalid">
          <div *ngIf="signupForm.get('category').errors.required">Category is required</div>
        </div>
      </div>
      <button class="buttonSignUp" [disabled]="!signupForm.valid" (click)="getDataRegister()">Sign up</button>
    </form>
  </div>
  <!---------------------------Sign up Form End---------------------------------->

  
  <!---------------------------Log in Form Start---------------------------------->
  <div *ngIf="!isSignUp" data-aos="slide-down">

    <form class="signin" [formGroup]="loginForm" autocomplete="off">
      <span class="text-right " (click)="goToHome()"><i class="fas fa-times-circle closeLogin"></i></span>
      <div class="text-center mt-3">
        <img src="/assets/favicon.png" class="loginLogo" />
      </div>
      <h1>Login</h1>
      <h2>Create account? <span (click)="isSignUpForm()">Sign Up</span></h2>
      <div class="signin__field">
        <input class="signin__input" formControlName="emailSignIn" type="email" required/>
        <label class="signin__label" for="email">Email</label>
      </div>
      <div class="alert alert-danger px-2 py-1"
        *ngIf="loginForm.get('emailSignIn').touched && loginForm.get('emailSignIn').invalid">
        <div *ngIf="loginForm.get('emailSignIn').errors.required">Email is required</div>
        <div *ngIf="loginForm.get('emailSignIn').errors.email">Email is not valid!</div>
      </div>
      <div class="signin__field">
        <input class="signin__input" type="password" formControlName="passwordLogin" required />
        <label class="signin__label" for="password">Password</label>
      </div>
      <div class="alert alert-danger px-2 py-1"
        *ngIf="loginForm.get('passwordLogin').touched && loginForm.get('passwordLogin').invalid">
        <div *ngIf="loginForm.get('passwordLogin').errors.required">Password is required</div>
        <div *ngIf="loginForm.get('passwordLogin').errors.minlength">Password is too Short!</div>
        <div *ngIf="loginForm.get('passwordLogin').errors.maxlength">Password is too Long!</div>
        <div *ngIf="loginForm.get('passwordLogin').errors.email">Password is not valid!</div>
      </div>

      <button class="buttonSignin" [disabled]="!loginForm.valid" (click)="getDataLogin()">Login</button>
      <p class="text-center pt-3"><a routerLink="/forget-password" class="forgetPassword">Forget Password?</a></p>
    </form>

  </div>
  <!---------------------------Log In Form End---------------------------------->



  <!--------------------------------------------------------------->
  <!------------------Main Login Page End-------------------------->
  <!--------------------------------------------------------------->




  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>