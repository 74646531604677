import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray ,Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import * as $ from 'jquery';
import LocationPicker from "location-picker";
import { AppService } from '../app.service';
import { CartService } from '../cart.service';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpClient, HttpEventType } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
@Component({
  selector: 'app-add-catering',
  templateUrl: './add-catering.component.html',
  styleUrls: ['./add-catering.component.css']
})
export class AddCateringComponent implements OnInit {


  @ViewChild('logoFile', { static: false }) logoFile : ElementRef;
  public imgLogoFile = null;
  public imgLogoFileName = null;
  public validLogoFile: boolean = false;
 
  @ViewChild('bannerImgFile', { static: false }) bannerImgFile: ElementRef;
  public imgBannerFile = null;
  public imgBannerFileName = null;
  public validBannerFile: boolean = false;

  @ViewChild('aboutusImgFile', { static: false }) aboutusImgFile: ElementRef;
  public imgAboutusFile = null;
  public imgAboutusFileName = null;
  public validAboutusFile: boolean = false;

  @ViewChild('staff1ImgFile', { static: false }) staff1ImgFile: ElementRef;
  public imgStaff1File = null;
  public imgStaff1FileName = null;
  public validStaff1File: boolean = false;

  @ViewChild('staff2ImgFile', { static: false }) staff2ImgFile: ElementRef;
  public imgStaff2File = null;
  public imgStaff2FileName = null;
  public validStaff2File: boolean = false;

  @ViewChild('staff3ImgFile', { static: false }) staff3ImgFile: ElementRef;
  public imgStaff3File = null;
  public imgStaff3FileName = null;
  public validStaff3File: boolean = false;

  @ViewChild('staff4ImgFile', { static: false }) staff4ImgFile: ElementRef;
  public imgStaff4File = null;
  public imgStaff4FileName = null;
  public validStaff4File: boolean = false;

  @ViewChild('gallery1Img', { static: false }) gallery1Img: ElementRef;
  public imgGallery1File = null;
  public imgGallery1FileName = null;
  public validGallery1File: boolean = false;

  @ViewChild('gallery2Img', { static: false }) gallery2Img: ElementRef;
  public imgGallery2File = null;
  public imgGallery2FileName = null;
  public validGallery2File: boolean = false;

  @ViewChild('gallery3Img', { static: false }) gallery3Img: ElementRef;
  public imgGallery3File = null;
  public imgGallery3FileName = null;
  public validGallery3File: boolean = false;

  @ViewChild('gallery4Img', { static: false }) gallery4Img: ElementRef;
  public imgGallery4File = null;
  public imgGallery4FileName = null;
  public validGallery4File: boolean = false;

  @ViewChild('gallery5Img', { static: false }) gallery5Img: ElementRef;
  public imgGallery5File = null;
  public imgGallery5FileName = null;
  public validGallery5File: boolean = false;

  @ViewChild('gallery6Img', { static: false }) gallery6Img: ElementRef;
  public imgGallery6File = null;
  public imgGallery6FileName = null;
  public validGallery6File: boolean = false;


  public mondayFriday = [
    {time : "08:00 - 21:00"},
    {time : "08:00 - 22:00"},
    {time : "08:00 - 23:00"},
    {time : "08:00 - 00:00"},
    {time : "09:00 - 20:00"},
    {time : "08:00 - 21:00"},
    {time : "08:00 - 22:00"},
    {time : "08:00 - 23:00"},
  ];
  public Saturday = [
    {time : "08:00 - 21:00"},
    {time : "08:00 - 22:00"},
    {time : "08:00 - 23:00"},
    {time : "08:00 - 00:00"},
    {time : "09:00 - 20:00"},
    {time : "08:00 - 21:00"},
    {time : "08:00 - 22:00"},
    {time : "08:00 - 23:00"},
  ];
  public Sunday = [
    {time : "08:00 - 21:00"},
    {time : "08:00 - 22:00"},
    {time : "08:00 - 23:00"},
    {time : "08:00 - 00:00"},
    {time : "09:00 - 20:00"},
    {time : "08:00 - 21:00"},
    {time : "08:00 - 22:00"},
    {time : "08:00 - 23:00"},
  ];


  creditCardNumber: string;
  public notifier: NotifierService;
  public isEnabled : boolean = false;
  public step1 : boolean = true;
  public step2  : boolean = false;
  public step3 : boolean = false;
  public step4 : boolean = false;
  submitted = false;
  contentSubmitted : boolean = false;
  accountSubmitted : boolean = false;
  lp: LocationPicker;
  public addCateringForm : FormGroup;
  public addContentForm : FormGroup;
  public addBankForm : FormGroup;
  @ViewChild('ccNumber') ccNumberField: ElementRef;
  public staff;
  expiryDate: any;
  progressCatering : number;
 

  constructor(public fb : FormBuilder , public service : AppService,  
    public notifierService: NotifierService, public router : Router , public cartService : CartService,
     public spinner: NgxSpinnerService, public http : HttpClient) {

    this.notifier = notifierService;

   }

  ngOnInit(): void {



    


this.addCateringForm = this.fb.group({

         
        company_name : ['' ,Validators.required],
        company_email : ['', [Validators.required , Validators.email]],
        landline : ['' , Validators.required],
        mobile : ['' , Validators.required],
        fax : ['' , Validators.required],
        address : ['' , Validators.required],
        zipcode : ['', Validators.required],
        city : ['' , Validators.required],
        state : ['' , Validators.required],

        banner_content : ['' , Validators.required],
        aboutus_content : ['' , Validators.required],
        facebook_link : ['' , Validators.required],
        instagram_link : ['' , Validators.required],
        landline_content : ['' , Validators.required],
        mobile_content : ['' , Validators.required],
        email_content : ['', [Validators.required , Validators.email]],
        address_content : ['' , Validators.required],
        vision_content : ['' , Validators.required],
        staff_name1 : ['' , Validators.required],
        staff_name2 : ['' , Validators.required],
        staff_name3 :  ['' , Validators.required],
        staff_name4 :  ['' , Validators.required],
        staff_designation1 :  ['' , Validators.required],
        staff_designation2 :  ['' , Validators.required],
        staff_designation3 :  ['' , Validators.required],
        staff_designation4 :  ['' , Validators.required],
        monday_friday : ['08:00 - 23:00' , Validators.required],
        saturday : ['08:00 - 00:00' , Validators.required],
        sunday : ['08:00 - 00:00' , Validators.required],
        logoImg : ['' , Validators.required],
        bannerImg :  ['' , Validators.required],
        aboutusImg :  ['' , Validators.required],
        staff1Img :  ['' , Validators.required],
        staff2Img :  ['' , Validators.required],
        staff3Img :  ['' , Validators.required],
        staff4Img :  ['' , Validators.required],
        gallery1Img :  ['' , Validators.required],
        gallery2Img :  ['' , Validators.required],
        gallery3Img :  ['' , Validators.required],
        gallery4Img :  ['' , Validators.required],
        gallery5Img :  ['' , Validators.required],
        gallery6Img :  ['' , Validators.required],

        owner_name : ['' , [Validators.required]],
        cvv : ['' ,  [Validators.required, Validators.maxLength(4), Validators.minLength(3)]],
        card_number : ['' , [Validators.required]],
        expiry_date : ['' ,  [Validators.required ]],

});



 this.lp = new LocationPicker('map' ,{
  setCurrentPosition: true,
}, {
  zoom: 15
});

 

}


 


get f() { return this.addCateringForm.controls; }
 
 




public formatString(event) {
  var inputChar = String.fromCharCode(event.keyCode);
  var code = event.keyCode;
  var allowedKeys = [8];
  if (allowedKeys.indexOf(code) !== -1) {
    return;
  }

  event.target.value = event.target.value.replace(
    /^([1-9]\/|[2-9])$/g, '0$1/' // 3 > 03/
  ).replace(
    /^(0[1-9]|1[0-2])$/g, '$1/' // 11 > 11/
  ).replace(
    /^([0-1])([3-9])$/g, '0$1/$2' // 13 > 01/3
  ).replace(
    /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2' // 141 > 01/41
  ).replace(
    /^([0]+)\/|[0]+$/g, '0' // 0/ > 0 and 00 > 0
  ).replace(
    /[^\d\/]|^[\/]*$/g, '' // To allow only digits and `/`
  ).replace(
    /\/\//g, '/' // Prevent entering more than 1 `/`
  );
}






cc_format(value: string) {
  const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  const matches = v.match(/\d{4,16}/g);
  const match = (matches && matches[0]) || '';
  const parts = [];
  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }
  if (parts.length > 0) {
    this.creditCardNumber = parts.join(' ');
  } else {
    this.creditCardNumber = value;
  }
  console.log(this.creditCardNumber);
}



 


public addCatering=()=>{
   
  this.accountSubmitted = true;
  if(this.addCateringForm.controls['owner_name'].invalid 
  || this.addCateringForm.controls['cvv'].invalid 
  || this.addCateringForm.controls['card_number'].invalid
  || this.addCateringForm.controls['expiry_date'].invalid)
  {
    this.step1 = false;
    this.step2 = false;
    this.step3 = true;
    this.step4 = false;
    this.spinner.hide();
    this.notifier.notify("error" , "Some Fields are not Valid!");
  }
  else
  {
   
    let x = {
      company_name : this.addCateringForm.controls['company_name'].value,
      company_email : this.addCateringForm.controls['company_email'].value,
      landline : this.addCateringForm.controls['landline'].value,
      mobile : this.addCateringForm.controls['mobile'].value,
      fax : this.addCateringForm.controls['fax'].value,
      address : this.addCateringForm.controls['address'].value,
      zipcode : this.addCateringForm.controls['zipcode'].value,
      city : this.addCateringForm.controls['city'].value,
      state : this.addCateringForm.controls['state'].value,
      latitude : this.lp.getMarkerPosition().lat,
      longitude : this.lp.getMarkerPosition().lng,
      banner_content : this.addCateringForm.controls['banner_content'].value,
      aboutus_content : this.addCateringForm.controls['aboutus_content'].value,
      facebook_link : this.addCateringForm.controls['facebook_link'].value,
      instagram_link : this.addCateringForm.controls['instagram_link'].value,
      landline_content : this.addCateringForm.controls['landline_content'].value,
      mobile_content : this.addCateringForm.controls['mobile_content'].value,
      email_content : this.addCateringForm.controls['email_content'].value,
      address_content : this.addCateringForm.controls['address_content'].value,
      vision_content : this.addCateringForm.controls['vision_content'].value,
      monday_friday : this.addCateringForm.controls['monday_friday'].value,
      saturday  : this.addCateringForm.controls['saturday'].value,
      sunday : this.addCateringForm.controls['sunday'].value,
   
   
     staff_name1 : this.addCateringForm.controls['staff_name1'].value,
     staff_name2 :  this.addCateringForm.controls['staff_name2'].value,
     staff_name3 :   this.addCateringForm.controls['staff_name3'].value,
     staff_name4 :  this.addCateringForm.controls['staff_name4'].value,
     staff_designation1 :  this.addCateringForm.controls['staff_designation1'].value,
     staff_designation2 :  this.addCateringForm.controls['staff_designation2'].value,
     staff_designation3 :  this.addCateringForm.controls['staff_designation3'].value,
     staff_designation4 :   this.addCateringForm.controls['staff_designation4'].value,
  
      owner_name : this.addCateringForm.controls['owner_name'].value,
      cvv : this.addCateringForm.controls['cvv'].value,
      card_number : this.creditCardNumber,
      expiry_date : this.expiryDate,
      
      logoImg : this.imgLogoFile,
      bannerImg :   this.imgBannerFile,
      aboutusImg :  this.imgAboutusFile,
      gallery1Img :  this.imgGallery1File,
      gallery2Img :  this.imgGallery2File,
      gallery3Img :   this.imgGallery3File,
      gallery4Img :  this.imgGallery4File,
      gallery5Img :  this.imgGallery5File, 
      gallery6Img :  this.imgGallery6File,
      staff1Img :  this.imgStaff1File,
      staff2Img :  this.imgStaff2File,
      staff3Img :   this.imgStaff3File,
      staff4Img :  this.imgStaff4File,
  
    };
    console.log("All Data" , x);      
        let a = this.getFormData(x);
        this.http.post("http://api.ninjashushi.ch/api/addprofile" , a , { reportProgress: true,
        observe: "events"
      }) .pipe(
        map((event: any) => {
          if (event.type == HttpEventType.UploadProgress) {
            this.progressCatering = Math.round((100 / event.total) * event.loaded);
             if(this.progressCatering === 100)
             {
              this.notifier.notify("info" , "Successfully Submitted!");
              this.router.navigate(['admin-catering']); 
              this.getSingleProfile();
             }
             
          } else if (event.type == HttpEventType.Response) {
            this.progressCatering = null;
          }
        }),
        catchError((err: any) => {
          this.progressCatering = null;
          this.notifier.notify("error" , err.message);
          return throwError(err.message);
        })
      )
      .toPromise();





  //        this.service.addprofile(a).subscribe((res : any)=>{
  //           console.log(res);
  //           if(res.status === 'True')
  //           {
  //             this.spinner.hide();
  //             this.notifier.notify("info" , "Successfully Submitted!");
  //             this.router.navigate(['admin-catering']);
              
  //             this.getSingleProfile();
  //           }
  //           else
  //           {
  //             this.notifier.notify("error" , "Something went wrong!");
  //             this.spinner.hide();
  //           }
           
  // });
 
  }




}








public getSingleProfile = () =>{
  this.spinner.show();
  this.service.getsingleprofile().subscribe((res : any)=>{
     console.log("Get Single APi" , res);
     if(res.status === "True")
     {
       if(res.Provider[0].approval_status === "pending" || (res.Provider[0].approval_status === "Approved"))
       {
          localStorage.setItem('cateringDashboard', res.Provider[0].approval_status);
          this.spinner.hide();
       }
       else
       {
          return;
       }
      
     }
  });
}








public getFormData = (object) => {
  const formData = new FormData();
  Object.keys(object).forEach(key => formData.append(key, object[key]));
  return formData;
}





public imgLogoPicker = (event) => {
  let onInnerSection: boolean = false;
  this.imgLogoFile = event.target.files[0];
  this.imgLogoFileName = event.target.files[0].name;
  let _validFileExtensions = ["image/png", "image/PNG"];

  for (let i = 0; i <= 4; i++) {
    if (_validFileExtensions[i] == event.target.files[0].type) {
      this.imgLogoFile = event.target.files[0];
      let kb = event.target.files[0].size / 1000;
      let mb = kb / 1000;
      if (mb > 5) {
        this.imgLogoFileName = null;
        this.validLogoFile = false;
        this.imgLogoFile = null;
        this.imgLogoFileName = "File must be less than 5 MB";
        return;
      }
      else {
        this.imgLogoFileName = null;
        onInnerSection = true;
        this.validLogoFile = true;
        this.imgLogoFileName = event.target.files[0].name;
      }
    }
    else if (onInnerSection == false) {
      this.imgLogoFileName = null;
      this.validLogoFile = false;
      this.imgLogoFile = null;
      this.imgLogoFileName = "Please Enter PNG File Type"
    }
  }

  console.log("Logo Image File Name" , this.imgLogoFileName);
  console.log("Logo Image File" , this.imgLogoFile);
}







public imgBannerPicker = (event) => {
  let onInnerSection: boolean = false;
  this.imgBannerFile = event.target.files[0];
  this.imgBannerFileName = event.target.files[0].name;
  let _validFileExtensions = ["image/jpg", "image/png", "image/jpeg", "image/bmp", "image/gif"];

  for (let i = 0; i <= 4; i++) {
    if (_validFileExtensions[i] == event.target.files[0].type) {
      this.imgBannerFile = event.target.files[0];
      let kb = event.target.files[0].size / 1000;
      let mb = kb / 1000;
      if (mb > 5) {
        this.imgBannerFileName = null;
        this.imgBannerFile = null;
        this.validBannerFile = false;
        this.imgBannerFileName = "File must be less than 5 MB";
        return;
      }
      else {
        this.imgBannerFileName = null;
        onInnerSection = true;
        this.validBannerFile = true;
        this.imgBannerFileName = event.target.files[0].name;
      }
    }
    else if (onInnerSection == false) {
      this.imgBannerFileName = null;
      this.validBannerFile = false;
      this.imgBannerFile = null;
      this.imgBannerFileName = "Please Enter Valid File Type"
    }
  }

  console.log("Banner Image File" , this.imgBannerFile);
}


public imgAboutUsPicker = (event) => {
  let onInnerSection: boolean = false;
  this.imgAboutusFile = event.target.files[0];
  this.imgAboutusFileName = event.target.files[0].name;
  let _validFileExtensions = ["image/jpg", "image/png", "image/jpeg", "image/bmp", "image/gif"];

  for (let i = 0; i <= 4; i++) {
    if (_validFileExtensions[i] == event.target.files[0].type) {
      this.imgAboutusFile = event.target.files[0];
      let kb = event.target.files[0].size / 1000;
      let mb = kb / 1000;
      if (mb > 5) {
        this.imgAboutusFileName = null;
        this.validAboutusFile = false;
        this.imgAboutusFile = null;
        this.imgAboutusFileName = "File must be less than 5 MB";
        return;
      }
      else {
        this.imgAboutusFileName = null;
        onInnerSection = true;
        this.validAboutusFile = true;
        this.imgAboutusFileName = event.target.files[0].name;
      }
    }
    else if (onInnerSection == false) {
      this.imgAboutusFileName = null;
      this.validAboutusFile = false;
      this.imgAboutusFile = null;
      this.imgAboutusFileName = "Please Enter Valid File Type"
    }
  }

  console.log("About Us File" , this.imgAboutusFile);
}


public imgStaff1Picker = (event) => {
  let onInnerSection: boolean = false;
  this.imgStaff1File = event.target.files[0];
  this.imgStaff1FileName = event.target.files[0].name;
  let _validFileExtensions = ["image/jpg", "image/png", "image/jpeg", "image/bmp", "image/gif"];

  for (let i = 0; i <= 4; i++) {
    if (_validFileExtensions[i] == event.target.files[0].type) {
      this.imgStaff1File = event.target.files[0];
      let kb = event.target.files[0].size / 1000;
      let mb = kb / 1000;
      if (mb > 5) {
        this.imgStaff1FileName = null;
        this.validStaff1File = false;
        this.imgStaff1File = null;
        this.imgStaff1FileName = "File must be less than 5 MB";
        return;
      }
      else {
        this.imgStaff1FileName = null;
        onInnerSection = true;
        this.validStaff1File = true;
        this.imgStaff1FileName = event.target.files[0].name;
      }
    }
    else if (onInnerSection == false) {
      this.imgStaff1FileName = null;
      this.validStaff1File = false;
      this.imgStaff1File = null;
      this.imgStaff1FileName = "Please Enter Valid File Type"
    }
  }

  console.log("Staff 1 File" , this.imgStaff1File);
}


public imgStaff2Picker = (event) => {
  let onInnerSection: boolean = false;
  this.imgStaff2File = event.target.files[0];
  this.imgStaff2FileName = event.target.files[0].name;
  let _validFileExtensions = ["image/jpg", "image/png", "image/jpeg", "image/bmp", "image/gif"];

  for (let i = 0; i <= 4; i++) {
    if (_validFileExtensions[i] == event.target.files[0].type) {
      this.imgStaff2File = event.target.files[0];
      let kb = event.target.files[0].size / 1000;
      let mb = kb / 1000;
      if (mb > 5) {
        this.imgStaff2FileName = null;
        this.validStaff2File = false;
        this.imgStaff2File = null;
        this.imgStaff2FileName = "File must be less than 5 MB";
        return;
      }
      else {
        this.imgStaff2FileName = null;
        onInnerSection = true;
        this.validStaff2File = true;
        this.imgStaff2FileName = event.target.files[0].name;
      }
    }
    else if (onInnerSection == false) {
      this.imgStaff2FileName = null;
      this.validStaff2File = false;
      this.imgStaff2File = null;
      this.imgStaff2FileName = "Please Enter Valid File Type"
    }
  }

  console.log("Staff 2 File" , this.imgStaff2File);
}

public imgStaff3Picker = (event) => {
  let onInnerSection: boolean = false;
  this.imgStaff3File = event.target.files[0];
  this.imgStaff3FileName = event.target.files[0].name;
  let _validFileExtensions = ["image/jpg", "image/png", "image/jpeg", "image/bmp", "image/gif"];

  for (let i = 0; i <= 4; i++) {
    if (_validFileExtensions[i] == event.target.files[0].type) {
      this.imgStaff3File = event.target.files[0];
      let kb = event.target.files[0].size / 1000;
      let mb = kb / 1000;
      if (mb > 5) {
        this.imgStaff3FileName = null;
        this.validStaff3File = false;
        this.imgStaff3File = null;
        this.imgStaff3FileName = "File must be less than 5 MB";
        return;
      }
      else {
        this.imgStaff3FileName = null;
        onInnerSection = true;
        this.validStaff3File = true;
        this.imgStaff3FileName = event.target.files[0].name;
      }
    }
    else if (onInnerSection == false) {
      this.imgStaff3FileName = null;
      this.validStaff3File = false;
      this.imgStaff3File = null;
      this.imgStaff3FileName = "Please Enter Valid File Type"
    }
  }

  console.log("Staff 3 File" , this.imgStaff3File);
}


public imgStaff4Picker = (event) => {
  let onInnerSection: boolean = false;
  this.imgStaff4File = event.target.files[0];
  this.imgStaff4FileName = event.target.files[0].name;
  let _validFileExtensions = ["image/jpg", "image/png", "image/jpeg", "image/bmp", "image/gif"];

  for (let i = 0; i <= 4; i++) {
    if (_validFileExtensions[i] == event.target.files[0].type) {
      this.imgStaff4File = event.target.files[0];
      let kb = event.target.files[0].size / 1000;
      let mb = kb / 1000;
      if (mb > 5) {
        this.imgStaff4FileName = null;
        this.validStaff4File = false;
        this.imgStaff4File = null;
        this.imgStaff4FileName = "File must be less than 5 MB";
        return;
      }
      else {
        this.imgStaff4FileName = null;
        onInnerSection = true;
        this.validStaff4File = true;
        this.imgStaff4FileName = event.target.files[0].name;
      }
    }
    else if (onInnerSection == false) {
      this.imgStaff4FileName = null;
      this.validStaff4File = false;
      this.imgStaff4File = null;
      this.imgStaff4FileName = "Please Enter Valid File Type"
    }
  }

  console.log("Staff 4 File" , this.imgStaff4File);
}


public imgGallery1Picker = (event) => {
  let onInnerSection: boolean = false;
  this.imgGallery1File = event.target.files[0];
  this.imgGallery1FileName = event.target.files[0].name;
  let _validFileExtensions = ["image/jpg", "image/png", "image/jpeg", "image/bmp", "image/gif"];

  for (let i = 0; i <= 4; i++) {
    if (_validFileExtensions[i] == event.target.files[0].type) {
      this.imgGallery1File = event.target.files[0];
      let kb = event.target.files[0].size / 1000;
      let mb = kb / 1000;
      if (mb > 5) {
        this.imgGallery1FileName = null;
        this.validGallery1File = false;
        this.imgGallery1File = null;
        this.imgGallery1FileName = "File must be less than 5 MB";
        return;
      }
      else {
        this.imgGallery1FileName = null;
        onInnerSection = true;
        this.validGallery1File = true;
        this.imgGallery1FileName = event.target.files[0].name;
      }
    }
    else if (onInnerSection == false) {
      this.imgGallery1FileName = null;
      this.validGallery1File = false;
      this.imgGallery1File = null;
      this.imgGallery1FileName = "Please Enter Valid File Type"
    }
  }

  console.log("Gallery 1 File" , this.imgGallery1File);
}

public imgGallery2Picker = (event) => {
  let onInnerSection: boolean = false;
  this.imgGallery2File = event.target.files[0];
  this.imgGallery2FileName = event.target.files[0].name;
  let _validFileExtensions = ["image/jpg", "image/png", "image/jpeg", "image/bmp", "image/gif"];

  for (let i = 0; i <= 4; i++) {
    if (_validFileExtensions[i] == event.target.files[0].type) {
      this.imgGallery2File = event.target.files[0];
      let kb = event.target.files[0].size / 1000;
      let mb = kb / 1000;
      if (mb > 5) {
        this.imgGallery2FileName = null;
        this.validGallery2File = false;
        this.imgGallery2File = null;
        this.imgGallery2FileName = "File must be less than 5 MB";
        return;
      }
      else {
        this.imgGallery2FileName = null;
        onInnerSection = true;
        this.validGallery2File = true;
        this.imgGallery2FileName = event.target.files[0].name;
      }
    }
    else if (onInnerSection == false) {
      this.imgGallery2FileName = null;
      this.validGallery2File = false;
      this.imgGallery2File = null;
      this.imgGallery2FileName = "Please Enter Valid File Type"
    }
  }

  console.log("Gallery 2 File" , this.imgGallery2File);
}


public imgGallery3Picker = (event) => {
  let onInnerSection: boolean = false;
  this.imgGallery3File = event.target.files[0];
  this.imgGallery3FileName = event.target.files[0].name;
  let _validFileExtensions = ["image/jpg", "image/png", "image/jpeg", "image/bmp", "image/gif"];

  for (let i = 0; i <= 4; i++) {
    if (_validFileExtensions[i] == event.target.files[0].type) {
      this.imgGallery3File = event.target.files[0];
      let kb = event.target.files[0].size / 1000;
      let mb = kb / 1000;
      if (mb > 5) {
        this.imgGallery3FileName = null;
        this.validGallery3File = false;
        this.imgGallery3File = null;
        this.imgGallery3FileName = "File must be less than 5 MB";
        return;
      }
      else {
        this.imgGallery3FileName = null;
        onInnerSection = true;
        this.validGallery3File = true;
        this.imgGallery3FileName = event.target.files[0].name;
      }
    }
    else if (onInnerSection == false) {
      this.imgGallery3FileName = null;
      this.validGallery3File = false;
      this.imgGallery3File = null;
      this.imgGallery3FileName = "Please Enter Valid File Type"
    }
  }

  console.log("Gallery 3 File" , this.imgGallery3File);
}


public imgGallery4Picker = (event) => {
  let onInnerSection: boolean = false;
  this.imgGallery4File = event.target.files[0];
  this.imgGallery4FileName = event.target.files[0].name;
  let _validFileExtensions = ["image/jpg", "image/png", "image/jpeg", "image/bmp", "image/gif"];

  for (let i = 0; i <= 4; i++) {
    if (_validFileExtensions[i] == event.target.files[0].type) {
      this.imgGallery4File = event.target.files[0];
      let kb = event.target.files[0].size / 1000;
      let mb = kb / 1000;
      if (mb > 5) {
        this.imgGallery4FileName = null;
        this.validGallery4File = false;
        this.imgGallery4File = null;
        this.imgGallery4FileName = "File must be less than 5 MB";
        return;
      }
      else {
        this.imgGallery4FileName = null;
        onInnerSection = true;
        this.validGallery4File = true;
        this.imgGallery4FileName = event.target.files[0].name;
      }
    }
    else if (onInnerSection == false) {
      this.imgGallery4FileName = null;
      this.validGallery4File = false;
      this.imgGallery4File = null;
      this.imgGallery4FileName = "Please Enter Valid File Type"
    }
  }

  console.log("Gallery 4 File" , this.imgGallery4File);
}


public imgGallery5Picker = (event) => {
  let onInnerSection: boolean = false;
  this.imgGallery5File = event.target.files[0];
  this.imgGallery5FileName = event.target.files[0].name;
  let _validFileExtensions = ["image/jpg", "image/png", "image/jpeg", "image/bmp", "image/gif"];

  for (let i = 0; i <= 4; i++) {
    if (_validFileExtensions[i] == event.target.files[0].type) {
      this.imgGallery5File = event.target.files[0];
      let kb = event.target.files[0].size / 1000;
      let mb = kb / 1000;
      if (mb > 5) {
        this.imgGallery5FileName = null;
        this.validGallery5File = false;
        this.imgGallery5File = null;
        this.imgGallery5FileName = "File must be less than 5 MB";
        return;
      }
      else {
        this.imgGallery5FileName = null;
        onInnerSection = true;
        this.validGallery5File = true;
        this.imgGallery5FileName = event.target.files[0].name;
      }
    }
    else if (onInnerSection == false) {
      this.imgGallery5FileName = null;
      this.validGallery5File = false;
      this.imgGallery5File = null;
      this.imgGallery5FileName = "Please Enter Valid File Type"
    }
  }

  console.log("Gallery 5 File" , this.imgGallery5File);
}


public imgGallery6Picker = (event) => {
  let onInnerSection: boolean = false;
  this.imgGallery6File = event.target.files[0];
  this.imgGallery6FileName = event.target.files[0].name;
  let _validFileExtensions = ["image/jpg", "image/png", "image/jpeg", "image/bmp", "image/gif"];

  for (let i = 0; i <= 4; i++) {
    if (_validFileExtensions[i] == event.target.files[0].type) {
      this.imgGallery6File = event.target.files[0];
      let kb = event.target.files[0].size / 1000;
      let mb = kb / 1000;
      if (mb > 5) {
        this.imgGallery6FileName = null;
        this.validGallery6File = false;
        this.imgGallery6File = null;
        this.imgGallery6FileName = "File must be less than 5 MB";
        return;
      }
      else {
        this.imgGallery6FileName = null;
        onInnerSection = true;
        this.validGallery6File = true;
        this.imgGallery6FileName = event.target.files[0].name;
      }
    }
    else if (onInnerSection == false) {
      this.imgGallery6FileName = null;
      this.validGallery6File = false;
      this.imgGallery6File = null;
      this.imgGallery6FileName = "Please Enter Valid File Type"
    }
  }

  console.log("Gallery 6 File" , this.imgGallery6File);
}



public step1Call = () => {
  this.step1 = true;
  this.step2 = false;
  this.step3 = false;
  this.step4 = false;
}



public step2Call = () => {

  this.submitted = true;

  if(this.addCateringForm.controls['company_name'].invalid 
    || this.addCateringForm.controls['company_email'].invalid 
    || this.addCateringForm.controls['landline'].invalid
    || this.addCateringForm.controls['mobile'].invalid
    || this.addCateringForm.controls['fax'].invalid
    || this.addCateringForm.controls['address'].invalid
    || this.addCateringForm.controls['zipcode'].invalid
    || this.addCateringForm.controls['city'].invalid
    || this.addCateringForm.controls['state'].invalid)
 {
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
    this.step4 = false;
    this.notifier.notify("error" , "Some Fields are not Valid!");
 }
 else
 {
    this.step1 = false;
    this.step2 = true;
    this.step3 = false;
    this.step4 = false;
 }


}


public step3Call = () => {

this.contentSubmitted = true;

if(this.addCateringForm.controls['logoImg'].invalid 
|| this.addCateringForm.controls['bannerImg'].invalid 
|| this.addCateringForm.controls['aboutusImg'].invalid
|| this.addCateringForm.controls['staff1Img'].invalid
|| this.addCateringForm.controls['staff2Img'].invalid
|| this.addCateringForm.controls['staff3Img'].invalid
|| this.addCateringForm.controls['staff4Img'].invalid
|| this.addCateringForm.controls['gallery1Img'].invalid
|| this.addCateringForm.controls['gallery2Img'].invalid
|| this.addCateringForm.controls['gallery3Img'].invalid
|| this.addCateringForm.controls['gallery4Img'].invalid
|| this.addCateringForm.controls['gallery5Img'].invalid
|| this.addCateringForm.controls['gallery6Img'].invalid
|| this.addCateringForm.controls['aboutus_content'].invalid 
|| this.addCateringForm.controls['banner_content'].invalid 
|| this.addCateringForm.controls['facebook_link'].invalid
|| this.addCateringForm.controls['instagram_link'].invalid
|| this.addCateringForm.controls['landline_content'].invalid
|| this.addCateringForm.controls['mobile_content'].invalid
|| this.addCateringForm.controls['email_content'].invalid
|| this.addCateringForm.controls['address_content'].invalid
|| this.addCateringForm.controls['vision_content'].invalid
|| this.addCateringForm.controls['staff_name1'].invalid
|| this.addCateringForm.controls['staff_name2'].invalid
|| this.addCateringForm.controls['staff_name3'].invalid
|| this.addCateringForm.controls['staff_name4'].invalid
|| this.addCateringForm.controls['staff_designation1'].invalid
|| this.addCateringForm.controls['staff_designation2'].invalid
|| this.addCateringForm.controls['staff_designation3'].invalid
|| this.addCateringForm.controls['staff_designation4'].invalid
)
{
this.step1 = false;
this.step2 = true;
this.step3 = false;
this.step4 = false;
this.notifier.notify("error" , "Some Fields are not Valid!");
}
else
{
this.step1 = false;
this.step2 = false;
this.step3 = true;
this.step4 = false;
}
}





public step4Call = () => {
  this.step1 = false;
  this.step2 = false;
  this.step3 = false;
  this.step4 = true;
}


}
