<!---------------------------------->
<!--Top Navbar Order By Phone Start-->
<!---------------------------------->
<nav class="navbar navbar-expand-md p-0 topBar">

  <div class="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2">
    <ul class="navbar-nav mr-auto d-flex justify-content-between px-5">
      <li class="nav-item">
        <small class="topNavbar_Text" *ngFor="let data of headerPhone"><i class="fas fa-phone-alt"></i>&nbsp;{{data.number}}</small>
      </li>
    </ul>
  </div>
  <div class="order-0 standardShippingText">
    <small class="navbar-brand topNavbar_Text" *ngFor="let data of topBarTitle">{{data.contents}}</small>
  </div>
  <div class="navbar-collapse collapse w-100 order-3 dual-collapse2">
    <ul class="navbar-nav ml-auto px-5">
      <li class="nav-item topNavbar_Social">
        <a class="nav-link topNavbar_Social px-2" target="_blank" [attr.href]="facebookLink"><i class="fab fa-facebook-square"></i></a>
      </li>
      <li class="nav-item topNavbar_Social">
        <a class="nav-link topNavbar_Social px-2" target="_blank" [attr.href]="instagramLink"><i class="fab fa-instagram"></i></a>
      </li>
      <li class="nav-item topNavbar_Social">
        <a class="nav-link topNavbar_Social px-2" target="_blank" [attr.href]="linkedinLink"><i class="fab fa-linkedin-in"></i></a>
      </li>

    </ul>
  </div>


</nav>
<!---------------------------------->
<!--Top Navbar Order By Phone Ends-->
<!---------------------------------->



<!---------------------------------->
<!--------main Navbar start--------->
<!---------------------------------->
<!-- <div class="container-fluid p-0 sticky-top overflow-hidden">  -->
 
  <nav class="navbar navbar-expand-md mainNavBar sticky-top">
    <!-- <a class="navbar-brand px-0 navbarBrandLOGO pl-1" (click)="routingCall('home-cheetah')">LOGO</a> -->
    <img src="/assets/logoRed.png" class="logoSize"/>
    <button class="navbar-toggler" type="button" data-toggle="modal" data-target="#sidebarModal">
      <span class="mainNavBars"><i class="fas fa-bars"></i></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav ml-auto ">
        <li class="nav-item navListSpace navListColor">
          <a class="nav-link navListColor" routerLinkActive="active-route" routerLink="/home-cheetah">Home</a>
        </li>
        <li class="nav-item navListSpace navListColor">
          <a class="nav-link navListColor" routerLinkActive="active-route" routerLink="/menu-cheetah">Menu</a>
        </li>
        <li class="nav-item navListSpace navListColor">
          <a class="nav-link navListColor" routerLinkActive="active-route" routerLink="/catering-cheetah">Catering</a>
        </li>
        <li class="nav-item navListSpace navListColor">
          <a class="nav-link navListColor" routerLinkActive="active-route" routerLink="/contact-cheetah">Contact</a>
        </li>
        <li class="nav-item navListSpace navListColor">
          <a class="nav-link navListColor" routerLinkActive="active-route" routerLink="/about-cheetah">About us</a>
        </li>
        <li class="nav-item" *ngIf="isShowSearch">
          <input type="text" class="form-control searchBar" placeholder="Search" />
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link navListColor" (click)="showSearchBar()"><i class="fas fa-search"></i></a>
        </li> -->
        <div class="search-toggle">
          <button class="search-icon icon-search"><i class="fa fa-search"></i></button>
          <button class="search-icon icon-close"><i class="fa fa-times"></i></button>
        </div>
        <div class="search-container" style="z-index: 1000;">
          <form>
            <input type="text" name="q" id="search-terms" placeholder="Search..." />
            <button type="button"  class="btn goSearchBtn"><i class="fa fa-search"></i></button>
          </form>
        </div>
        <li class="nav-item">
          <a class="nav-link navListColor notification" routerLinkActive="active-route" routerLink="/cart">
            <i class="fas fa-shopping-cart"></i>
            <span class="badge" >{{cartLengthHeader}}</span>
          </a>
        </li>
        <li class="nav-item navListSpace pr-2">
          <button class="btn navBarLoginBtn" *ngIf="isLogin" (click)="routingCall('login')">Login</button>
          <button class="btn navBarLoginBtn" *ngIf="!isLogin" (click)="openModal()">Logout </button>
        </li>
      </ul>
    </div>
  </nav>
 
<!-- </div> -->
<!---------------------------------->
<!--------main Navbar End--------->
<!---------------------------------->




<!---------------------------------->
<!--------SideBar Start------------->
<!---------------------------------->
<div class="container-fluid">
  <div class="modal left fade menu" id="sidebarModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog position-fixed m-auto" role="document">
      <div class="modal-content h-100">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Search">
          <div class="input-group-append">
            <button class="btn sideSearchBtn" data-dismiss="modal" type="button">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
        <div class="modal-body p-3">
          <div class="nav flex-column">
            <a href="#" data-dismiss="modal" (click)="routingCall('cart')"
            class="nav-item nav-link sidebarMenu">
            Cart 
            <i class="fas fa-shopping-cart"></i>&nbsp;
            <span class="badge badge-danger">{{cartLengthHeader}}</span>
          </a>
            <a class="nav-item nav-link sidebarMenu" (click)="routingCall('home-cheetah')" data-dismiss="modal"
            href="#">Home</a>
            <a class="nav-item nav-link sidebarMenu" (click)="routingCall('about-cheetah')" data-dismiss="modal"
              href="#">About us</a>
            <a href="#" data-dismiss="modal" (click)="routingCall('menu-cheetah')"
              class="nav-item nav-link sidebarMenu">Menu</a>
            <a href="#" data-dismiss="modal" class="nav-item nav-link sidebarMenu"
              (click)="routingCall('catering-cheetah')">Catering</a>
            <a href="#" data-dismiss="modal" (click)="routingCall('contact-cheetah')"
              class="nav-item nav-link sidebarMenu">Contact</a>
            <a href="#" data-dismiss="modal" *ngIf="!isLogin" class="nav-item nav-link sidebarMenu" (click)="openModal()">Logout</a>
            <a href="#" data-dismiss="modal" *ngIf="isLogin" class="nav-item nav-link sidebarMenu" (click)="routingCall('login')">Login / Register</a>
          </div>
          <div class="d-flex justify-content-around mt-4">
            <a class="nav-link navbar_Social_sidebar px-2"  target="_blank" [attr.href]="linkedinLink"><i class="fab fa-linkedin-in"></i></a>
            <a class="nav-link navbar_Social_sidebar px-2" target="_blank"  [attr.href]="instagramLink"><i class="fab fa-instagram"></i></a>
            <a class="nav-link navbar_Social_sidebar px-2"  target="_blank" [attr.href]="facebookLink"><i class="fab fa-facebook-square"></i></a>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary sideBarCloseBtn" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!---------------------------------->
<!--------SideBar End------------->
<!---------------------------------->






<!-- =================================-->
<!--------- Logout Modal start--------->
<!-- =================================-->
<modal #myModal style="text-align: -webkit-center;">
  <modal-header>
    <div class="row">
      <div class="col">
        <div class="container">
          <b  >Are you Sure?</b>
        </div>

      </div>
    </div>
  </modal-header>

  <modal-content>
        <div class="d-flex justify-content-around">
             <button type="button" class="btn btn-danger btn-sm" (click)="logout()">Logout <span><i class="fa fa-check" aria-hidden="true"></i></span></button>&nbsp;
             <button type="button" class="btn btn-success btn-sm" (click)="closeModal()">Cancel <i class="fa fa-times" aria-hidden="true"></i>
             </button>
        </div>
  </modal-content>

</modal>
<!-- =================================-->
<!---------Logout Modal End--------->
<!-- =================================-->