<!----------------------------->
<!--------Footer Start--------->
<!----------------------------->

<div class="container-fluid p-0 footer_body">
  <div class="container">
    <div class="row p-2">
      <div class="col d-flex justify-content-between">
        <div>
          <span class="footer_address" *ngFor="let data of footerAddress">{{data.contents}}</span>
          <span class="footer_call" *ngFor="let data of footerPhone"><i class="fa fa-phone-alt" aria-hidden="true"></i>&nbsp;&nbsp;{{data.number}}</span>
        </div>
        <div>
          <a class="footer_social" target="_blank" [attr.href]="facebookLink"><i class="fab fa-facebook-square"></i></a>
          <a class="footer_social" target="_blank" [attr.href]="instagramLink"><i class="fab fa-instagram"></i></a>
          <a class="footer_social" target="_blank" [attr.href]="linkedinLink"><i class="fab fa-linkedin-in"></i></a>
        </div>
      </div>
</div>
  </div>

</div>

<!----------------------------->
<!--------Footer End--------->
<!----------------------------->
