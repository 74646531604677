import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import LocationPicker from 'location-picker';
import * as $ from 'jquery';
import { luhnValidator } from '../validators/luhnValidator';
import { getValidationConfigFromCardNo } from '../helpers/card.helper';
import { Router } from '@angular/router';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import * as AOS from 'aos';
 
@Component({
  selector: 'app-review-order',
  templateUrl: './review-order.component.html',
  styleUrls: ['./review-order.component.css']
})
export class ReviewOrderComponent implements OnInit {

  
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;

public isPromo : boolean = false;
public submitted : boolean = false;
public personalName  = "Muhammad Faizan";
public personalEmail = "faizanshakeel54@gmail.com";
public personalContact = "+923203940330";

public isEditPersonal : boolean = false;  
public isAddCard : boolean = false;
public lp: LocationPicker;
cardNumberGroup: FormGroup;
public paymentType = "Cash";
public isAddedCard : boolean = false;
cartItems: any;
public date = new Date().toLocaleString();
public totalPrice : number = 0;
public editPerosnalForm : FormGroup;
public promoForm : FormGroup;
  tempCart: any;


// @ViewChild("placesRef") placesRef : GooglePlaceDirective;


  constructor(public fb : FormBuilder, public router : Router) { }

  ngOnInit(): void {
    AOS.init({
      duration: 1900,
    });

 
this.getProducts();

    this.lp = new LocationPicker('map', {
      setCurrentPosition: true,
    }, {
      zoom: 15
    });
     

      
 this.promoForm = this.fb.group({
       promoInput : ['']
 });


 


    this.cardNumberGroup = new FormGroup({
      cardNumber: new FormControl('', [
          Validators.required,
          Validators.minLength(12),
          luhnValidator()
        ]),
      cvvNumber : new FormControl('' , [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(4),
      ]),  
      expiryDate : new FormControl('', [
        Validators.required,
          Validators.maxLength(5),
      ])
  
    })
  


  }



 



 public getCardNumberControl(): AbstractControl | null {
    return this.cardNumberGroup && this.cardNumberGroup.get('cardNumber');
  }




 




  cardMaskFunction(rawValue: string): Array<RegExp> {
    const card = getValidationConfigFromCardNo(rawValue);
    if (card) {
      return card.mask;
    }
    return [/\d/];
  }







  public formatString(event) {
    var inputChar = String.fromCharCode(event.keyCode);
    var code = event.keyCode;
    var allowedKeys = [8];
    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }
  
    event.target.value = event.target.value.replace(
      /^([1-9]\/|[2-9])$/g, '0$1/' // 3 > 03/
    ).replace(
      /^(0[1-9]|1[0-2])$/g, '$1/' // 11 > 11/
    ).replace(
      /^([0-1])([3-9])$/g, '0$1/$2' // 13 > 01/3
    ).replace(
      /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2' // 141 > 01/41
    ).replace(
      /^([0]+)\/|[0]+$/g, '0' // 0/ > 0 and 00 > 0
    ).replace(
      /[^\d\/]|^[\/]*$/g, '' // To allow only digits and `/`
    ).replace(
      /\/\//g, '/' // Prevent entering more than 1 `/`
    );
  }
  






public addCard=()=>{
   let x = {
     card_number : this.cardNumberGroup.controls['cardNumber'],
     cvv : this.cardNumberGroup.controls['cvvNumber'],
     expiry_date : this.cardNumberGroup.controls['expiryDate'],
   };
   console.log(x);
}





 public paymentMethod=(event)=>{
   this.paymentType = event;
  
   if(this.paymentType === "addCard")
   {
     this.isAddCard = true;
   }
   else if(this.paymentType === "addedCard")
   {
     this.isAddCard = false;
   }
   else if(this.paymentType === "Cash")
   {
     this.isAddCard = false;
   }
   else if(this.paymentType === "Wallet")
   {
     this.isAddCard = false;
   }
   else
   {
     return false;
   }
 }

















public getProducts=()=>{
    var a = localStorage.getItem('products');
    this.cartItems = JSON.parse(a);
    console.log("Products in Cart" , this.cartItems);
    this.cartItems.forEach(element => {
       this.totalPrice = element.calcPrice + this.totalPrice;
    });

}
















public goToCart=()=>{
  this.router.navigate(['cart']);
}















public isEdit=()=>{
  this.isEditPersonal = true;
 
}















public savePersonalEdit=()=>{

 // this.submitted = true;
  let x = {
    name : this.personalName,
    email : this.personalEmail,
    contact : this.personalContact,
  };

  this.isEditPersonal = false;
  console.log(x);

}






// public handleAddressChange(address: Address) {
//   console.log("address" , address);
// }



public placeOrder=()=>{
  console.log("Cart Items" , this.cartItems);
}




public showPromo=()=>{
   this.isPromo = true;
}


public addPromo=()=>{
  let x = {
    promoCode : this.promoForm.controls['promoInput'].value
  };
  
  this.promoForm.reset();
  this.isPromo = false;
}


       

}
