import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModuleRoutingModule } from './shared-module-routing.module';
import { CheetayHeaderComponent } from './cheetay-header/cheetay-header.component';
import { CheetayFooterComponent } from './cheetay-footer/cheetay-footer.component';
import { ModalModule } from 'ngb-modal';


@NgModule({
  declarations: [CheetayHeaderComponent, CheetayFooterComponent],
  imports: [
    CommonModule,
    SharedModuleRoutingModule,
    ModalModule
  ],
  exports : [
    CheetayHeaderComponent, CheetayFooterComponent
  ],
})
export class SharedModuleModule { }
