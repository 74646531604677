<!----------------------Forget Password Page Start---------------------------->


<div class="container-fluid forgetPasswordPageBg LeafBG">
  <notifier-container></notifier-container>
  <!--------------------Forget Password Form Start---------------------->
  <div>
    <form class="signin" [formGroup]="forgetPassword" autocomplete="off">
      <span class="text-right" (click)="goToHome()"><i class="fas fa-times-circle  closeForget"></i></span>
      <div class="text-center mt-3">
        <img src="/assets/ravian-food/login-signup/key.png" class="loginLogo" />
      </div>
      <h1>Forget Password</h1>
      <div class="signin__field mt-5">
        <input class="signin__input" formControlName="emailForget" type="email" required />
        <label class="signin__label" for="email">Email</label>
      </div>
      <div class="alert alert-danger px-2 py-1" *ngIf="forgetPassword.get('emailForget').touched && forgetPassword.get('emailForget').invalid">
        <div *ngIf="forgetPassword.get('emailForget').errors.required">Email is required</div>
        <div *ngIf="forgetPassword.get('emailForget').errors.minlength">Email is too Short!</div>
        <div *ngIf="forgetPassword.get('emailForget').errors.maxlength">Email is too Long!</div>
        <div *ngIf="forgetPassword.get('emailForget').errors.email">Email is not valid!</div>
      </div>
      <button class="buttonSignin" [disabled]="!forgetPassword.valid" (click)="getEmailData(forgetPassword)">Send</button>
      <p class="text-center pt-3"><a routerLink="/login" class="forgetPassword">Back to Login Page</a></p>
    </form>
  </div>
  <!--------------------Forget Password Form End---------------------->





</div>

<!----------------------Forget Password Page End------------------------>











