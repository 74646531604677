import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class CateringGuradService implements CanActivate {

  constructor(public service: AppService, public router: Router) { }


  canActivate(): boolean {
    if (this.service.catering_varifying()) {
      return true;
    }
    else {
      this.router.navigate(["/login"]);
      return false;
    }
  }



}
