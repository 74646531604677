import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})


export class AppService {
  public BaseURl = environment.BaseUrl;

  constructor(public http: HttpClient) { }

  public login = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/login', obj);
  }

  public register = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/register', obj);
  }

  public getimageSliderWeb = (): Observable<any[]> => {
    return this.http.get<any[]>(this.BaseURl + 'api/getimageSliderWeb');
  }

  public getContactWeb = (): Observable<any[]> => {
    return this.http.get<any[]>(this.BaseURl + 'api/getContactWeb');
  }

  public getSocialLinksWeb = (): Observable<any[]> => {
    return this.http.get<any[]>(this.BaseURl + 'api/getSocialLinksWeb');
  }

  public getContentWeb = (): Observable<any[]> => {
    return this.http.get<any[]>(this.BaseURl + 'api/getContentWeb');
  }

  public getOtherImagesWeb = (): Observable<any[]> => {
    return this.http.get<any[]>(this.BaseURl + 'api/getOtherImagesWeb');
  }

  public contactGlobal = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/contactGlobal' , obj);
  }

  public user_feedback = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/user_feedback' , obj);
  }


  public addprofile = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/addprofile' , obj);
  }

  public getsingleprofile = (): Observable<any[]> => {
    return this.http.get<any[]>(this.BaseURl + 'api/getsingleprofile');
  }


  public getcateringServices = () =>{
    return this.http.get<any[]>(this.BaseURl + 'api/getcateringServices');
  }


  public getsinglecateringServices = (obj) =>{
    return this.http.post<any[]>(this.BaseURl + 'api/getsinglecateringServices' , obj);
  }



  public book_order = (obj) =>{
    return this.http.post<any[]>(this.BaseURl + 'api/book_order' , obj);
  }


  public addprofileRestaurant = (obj) =>{
    return this.http.post<any[]>(this.BaseURl + 'api/addprofileRestaurant' , obj);
  }


  public addRestaurantPromo = (obj) =>{
    return this.http.post<any[]>(this.BaseURl + 'api/addRestaurantPromo' , obj);
  }


  public getAllRestaurant = () =>{
    return this.http.get<any[]>(this.BaseURl + 'api/getAllRestaurant');
  }



  public getSingleRestaurant = (obj) =>{
    return this.http.post<any[]>(this.BaseURl + 'api/getSingleRestaurant' , obj);
  }


  public getSingleRestaurantProfile = () =>{
    return this.http.get<any[]>(this.BaseURl + 'api/getSingleRestaurantProfile');
  }


  public getOrders = () =>{
    return this.http.get<any[]>(this.BaseURl + 'api/getOrders');
  }


  public deleteOrder = (obj) =>{
    return this.http.post<any[]>(this.BaseURl + 'api/deleteOrder' , obj);
  }

  public seenorder = (obj) =>{
    return this.http.post<any[]>(this.BaseURl + 'api/seenorder' , obj);
  }

  public contactreadcatering = (obj) =>{
    return this.http.post<any[]>(this.BaseURl + 'api/contactreadcatering' , obj);
  }


  public contactdeletecatering = (obj) =>{
    return this.http.post<any[]>(this.BaseURl + 'api/contactdeletecatering' , obj);
  }



  public addFood = (obj) =>{
    return this.http.post<any[]>(this.BaseURl + 'api/addFood' , obj);
  }


  public getFood = () =>{
    return this.http.get<any[]>(this.BaseURl + 'api/getFood');
  }


  public deleteFood = (obj) =>{
    return this.http.post<any[]>(this.BaseURl + 'api/deleteFood' , obj);
  }

  public updateFood = (obj) =>{
    return this.http.post<any[]>(this.BaseURl + 'api/updateFood' , obj);
  }


  public getSingleMenu = (obj) =>{
    return this.http.post<any[]>(this.BaseURl + 'api/getSingleMenu' , obj);
  }


  public getAllMenu = () =>{
    return this.http.get<any[]>(this.BaseURl + 'api/getAllMenu');
  }




  public idbasedfood = (obj) =>{
    return this.http.post<any[]>(this.BaseURl + 'api/idbasedfood', obj);
  }




  
  public getToken=()=>{
    return localStorage.getItem('tokenCheetah');
  }


  public user_varifying() {
    return !!localStorage.getItem('tokenUser');
  }


  public catering_varifying() {
    return !!localStorage.getItem('tokenCatering');
  }

  public restaurant_varifying() {
    return !!localStorage.getItem('tokenRestaurant');
  }



}
