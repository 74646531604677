import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import LocationPicker from 'location-picker';
import { AppService } from '../app.service';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpClient, HttpEventType } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
@Component({
  selector: 'app-add-restaurant',
  templateUrl: './add-restaurant.component.html',
  styleUrls: ['./add-restaurant.component.css']
})
export class AddRestaurantComponent implements OnInit {



  @ViewChild('promoVideoFile', { static: false }) promoVideoFile: ElementRef;
  public promoVideooFile = null;
  public promoVideoName = null;
  public validVideoPromo: boolean = false;




  @ViewChild('logoFile', { static: false }) logoFile: ElementRef;
  public imgLogoFile = null;
  public imgLogoFileName = null;
  public validLogoFile: boolean = false;


  @ViewChild('bannerImgFile', { static: false }) bannerImgFile: ElementRef;
  public imgBannerFile = null;
  public imgBannerFileName = null;
  public validBannerFile: boolean = false;



  @ViewChild('specialDish1ImgFile', { static: false }) specialDish1ImgFile: ElementRef;
  public imgSpecial1File = null;
  public imgSpecial1FileName = null;
  public validSpecial1File: boolean = false;

  @ViewChild('specialDish2ImgFile', { static: false }) specialDish2ImgFile: ElementRef;
  public imgSpecial2File = null;
  public imgSpecial2FileName = null;
  public validSpecial2File: boolean = false;

  @ViewChild('specialDish3ImgFile', { static: false }) specialDish3ImgFile: ElementRef;
  public imgSpecial3File = null;
  public imgSpecial3FileName = null;
  public validSpecial3File: boolean = false;

  @ViewChild('specialDish4ImgFile', { static: false }) specialDish4ImgFile: ElementRef;
  public imgSpecial4File = null;
  public imgSpecial4FileName = null;
  public validSpecial4File: boolean = false;

  @ViewChild('specialDish5ImgFile', { static: false }) specialDish5ImgFile: ElementRef;
  public imgSpecial5File = null;
  public imgSpecial5FileName = null;
  public validSpecial5File: boolean = false;

  @ViewChild('featureDish1ImgFile', { static: false }) featureDish1ImgFile: ElementRef;
  public imgFeature1File = null;
  public imgFeature1FileName = null;
  public validFeature1File: boolean = false;

  @ViewChild('featureDish2ImgFile', { static: false }) featureDish2ImgFile: ElementRef;
  public imgFeature2File = null;
  public imgFeature2FileName = null;
  public validFeature2File: boolean = false;

  @ViewChild('featureDish3ImgFile', { static: false }) featureDish3ImgFile: ElementRef;
  public imgFeature3File = null;
  public imgFeature3FileName = null;
  public validFeature3File: boolean = false;

  public accountSubmitted : boolean = false;
  public contentSubmitted: boolean = false;
  public submitted: boolean = false;
  public notifier: NotifierService;
  public isEnabled: boolean = false;

  public step1: boolean = true;
  public step2: boolean = false;
  public step3: boolean = false;
  public step4: boolean = false;

  public addRestaurantForm: FormGroup;
  public promoVideoForm: FormGroup;
  public lp: LocationPicker;
  creditCardNumber: string;
  expiryDate: any;
  progress: number;
  progressRestaurant : number;
  constructor(public router: Router, public fb: FormBuilder, public notifierService: NotifierService,
     public service: AppService, public spinner: NgxSpinnerService, public http : HttpClient) {

    this.notifier = notifierService;

  }

  ngOnInit(): void {


    this.promoVideoForm = this.fb.group({
      promoVideo: [null, Validators.required]
    });





    this.addRestaurantForm = this.fb.group({

      restaurant_name: ['', Validators.required],
      restaurant_email: ['', [Validators.required, Validators.email]],
      landline: ['', Validators.required],
      mobile: ['', Validators.required],
      fax: ['', Validators.required],
      address: ['', Validators.required],
      zipcode: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      aboutus_content: ['', Validators.required],
      chef_signature: ['', Validators.required],
      chef_content: ['', Validators.required],
      facebook_link: ['', Validators.required],
      instagram_link: ['', Validators.required],
      landline_content: ['', Validators.required],
      mobile_content: ['', Validators.required],
      email_content: ['', [Validators.required, Validators.email]],
      address_content: ['', Validators.required],
      // special_dish1 : ['' , Validators.required],
      // special_dish2 : ['' , Validators.required],
      // special_dish3 :  ['' , Validators.required],
      // special_dish4 :  ['' , Validators.required],
      // special_dish5 : ['' , Validators.required],
      // specialDishPrice1 :  ['' , Validators.required],
      // specialDishPrice2 :  ['' , Validators.required],
      // specialDishPrice3 :  ['' , Validators.required],
      // specialDishPrice4 :  ['' , Validators.required],
      // specialDishPrice5 : ['' , Validators.required],

      feature_dish1: ['', Validators.required],
      feature_dish2: ['', Validators.required],
      feature_dish3: ['', Validators.required],
      featureDishPrice1: ['', Validators.required],
      featureDishPrice2: ['', Validators.required],
      featureDishPrice3: ['', Validators.required],


      logoImg: [null, Validators.required],
      bannerImg: [null, Validators.required],

     // aboutusImg: ['', Validators.required],
      // specialDish1Img :  ['' , Validators.required],
      // specialDish2Img :  ['' , Validators.required],
      // specialDish3Img :  ['' , Validators.required],
      // specialDish4Img :  ['' , Validators.required],
      // specialDish5Img  :  ['' , Validators.required],


      featureDish1Img: [null, Validators.required],
      featureDish2Img: [null, Validators.required],
      featureDish3Img: [null, Validators.required],

      owner_name : ['' , [Validators.required]],
      cvv : ['' ,  [Validators.required, Validators.maxLength(4), Validators.minLength(3)]],
      card_number : ['' , [Validators.required]],
      expiry_date : ['' ,  [Validators.required ]],

    });



    this.lp = new LocationPicker('map', {
      setCurrentPosition: true,
    }, {
      zoom: 15
    });


  }



  get f() { return this.addRestaurantForm.controls; }






  upload(file) {
    this.progress = 1;
    const formData = new FormData();
    formData.append("file", file);

    this.http
      .post("http://api.ninjashushi.ch/api/addRestaurantPromo", formData, {
        reportProgress: true,
        observe: "events"
      })
      .pipe(
        map((event: any) => {
          if (event.type == HttpEventType.UploadProgress) {
            this.progress = Math.round((100 / event.total) * event.loaded);
             if(this.progress === 100)
             {
              this.router.navigate(['admin-restaurant']);
              this.getSingleRestaurantProfile();
             }
             
          } else if (event.type == HttpEventType.Response) {
            this.progress = null;
          }
        }),
        catchError((err: any) => {
          this.progress = null;
          alert(err.message);
          return throwError(err.message);
        })
      )
      .toPromise();
  }




  public addVideo = () => {
    let x = {
      promo_video: this.promoVideooFile,
    };

    
    let a = this.getFormData(x);

    this.http.post("http://api.ninjashushi.ch/api/addRestaurantPromo" , a , { reportProgress: true,
    observe: "events"
  }) .pipe(
    map((event: any) => {
      if (event.type == HttpEventType.UploadProgress) {
        this.progress = Math.round((100 / event.total) * event.loaded);
         if(this.progress === 100)
         {
          this.router.navigate(['admin-restaurant']);
          this.getSingleRestaurantProfile();
         }
         
      } else if (event.type == HttpEventType.Response) {
        this.progress = null;
      }
    }),
    catchError((err: any) => {
      this.progress = null;
      this.notifier.notify("error" , err.message);
      return throwError(err.message);
    })
  )
  .toPromise();
    // this.service.addRestaurantPromo(a).subscribe((res: any) => {
    //   console.log(res);
    //   if (res.status === "True") {
    //     this.router.navigate(['admin-restaurant']);
    //     this.getSingleRestaurantProfile();
    //   }
    // });

  }









  public formatString(event) {
    var inputChar = String.fromCharCode(event.keyCode);
    var code = event.keyCode;
    var allowedKeys = [8];
    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }
  
    event.target.value = event.target.value.replace(
      /^([1-9]\/|[2-9])$/g, '0$1/' // 3 > 03/
    ).replace(
      /^(0[1-9]|1[0-2])$/g, '$1/' // 11 > 11/
    ).replace(
      /^([0-1])([3-9])$/g, '0$1/$2' // 13 > 01/3
    ).replace(
      /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2' // 141 > 01/41
    ).replace(
      /^([0]+)\/|[0]+$/g, '0' // 0/ > 0 and 00 > 0
    ).replace(
      /[^\d\/]|^[\/]*$/g, '' // To allow only digits and `/`
    ).replace(
      /\/\//g, '/' // Prevent entering more than 1 `/`
    );
  }
  
  
  
  
  
  
  cc_format(value: string) {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = v.match(/\d{4,16}/g);
    const match = (matches && matches[0]) || '';
    const parts = [];
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }
    if (parts.length > 0) {
      this.creditCardNumber = parts.join(' ');
    } else {
      this.creditCardNumber = value;
    }
   
  }
















  public addRestaurant = () => {
   
    this.accountSubmitted = true;
    if(this.addRestaurantForm.controls['owner_name'].invalid 
    || this.addRestaurantForm.controls['cvv'].invalid 
    || this.addRestaurantForm.controls['card_number'].invalid
    || this.addRestaurantForm.controls['expiry_date'].invalid)
    {
      this.step1 = false;
      this.step2 = false;
      this.step3 = true;
      this.step4 = false;
     
      this.notifier.notify("error" , "Some Fields are not Valid!");
    }
    else
    {
    let x = {
      restaurant_name: this.addRestaurantForm.controls['restaurant_name'].value,
      restaurant_email: this.addRestaurantForm.controls['restaurant_email'].value,
      landline: this.addRestaurantForm.controls['landline'].value,
      mobile: this.addRestaurantForm.controls['mobile'].value,
      fax: this.addRestaurantForm.controls['fax'].value,
      address: this.addRestaurantForm.controls['address'].value,
      zipcode: this.addRestaurantForm.controls['zipcode'].value,
      city: this.addRestaurantForm.controls['city'].value,
      state: this.addRestaurantForm.controls['state'].value,
      latitude: this.lp.getMarkerPosition().lat,
      longitude: this.lp.getMarkerPosition().lng,
      aboutus_content: this.addRestaurantForm.controls['aboutus_content'].value,
      chef_signature: this.addRestaurantForm.controls['chef_signature'].value,
      chef_content: this.addRestaurantForm.controls['chef_content'].value,
      facebook_link: this.addRestaurantForm.controls['facebook_link'].value,
      instagram_link: this.addRestaurantForm.controls['instagram_link'].value,
      landline_content: this.addRestaurantForm.controls['landline_content'].value,
      mobile_content: this.addRestaurantForm.controls['mobile_content'].value,
      email_content: this.addRestaurantForm.controls['email_content'].value,
      address_content: this.addRestaurantForm.controls['address_content'].value,

      // special_dish1 : this.addRestaurantForm.controls['special_dish1'].value,
      // special_dish2 : this.addRestaurantForm.controls['special_dish2'].value,
      // special_dish3 :  this.addRestaurantForm.controls['special_dish3'].value,
      // special_dish4 : this.addRestaurantForm.controls['special_dish4'].value,
      // special_dish5 : this.addRestaurantForm.controls['special_dish5'].value,
      // specialDishPrice1 : this.addRestaurantForm.controls['specialDishPrice1'].value,
      // specialDishPrice2 : this.addRestaurantForm.controls['specialDishPrice2'].value,
      // specialDishPrice3 : this.addRestaurantForm.controls['specialDishPrice3'].value,
      // specialDishPrice4 : this.addRestaurantForm.controls['specialDishPrice4'].value,
      // specialDishPrice5 :this.addRestaurantForm.controls['specialDishPrice5'].value,
      // specialDish1Img : this.imgSpecial1File,
      // specialDish2Img : this.imgSpecial2File,
      // specialDish3Img :  this.imgSpecial3File,
      // specialDish4Img : this.imgSpecial4File,
      // specialDish5Img  : this.imgSpecial5File,

      feature_dish1: this.addRestaurantForm.controls['feature_dish1'].value,
      feature_dish2: this.addRestaurantForm.controls['feature_dish2'].value,
      feature_dish3: this.addRestaurantForm.controls['feature_dish3'].value,
      featureDishPrice1: this.addRestaurantForm.controls['featureDishPrice1'].value,
      featureDishPrice2: this.addRestaurantForm.controls['featureDishPrice2'].value,
      featureDishPrice3: this.addRestaurantForm.controls['featureDishPrice3'].value,
      featureDish1Img: this.imgFeature1File,
      featureDish2Img: this.imgFeature2File,
      featureDish3Img: this.imgFeature3File,

      logoImg: this.imgLogoFile,
      bannerImg: this.imgBannerFile,

      owner_name: this.addRestaurantForm.controls['owner_name'].value,
      cvv: this.addRestaurantForm.controls['cvv'].value,
      card_number: this.addRestaurantForm.controls['card_number'].value,
      expiry_date: this.addRestaurantForm.controls['expiry_date'].value,
    };


  

    let a = this.getFormData(x);
   
    this.http.post("http://api.ninjashushi.ch/api/addprofileRestaurant" , a , { reportProgress: true,
    observe: "events"
  }) .pipe(
    map((event: any) => {
      if (event.type == HttpEventType.UploadProgress) {
        this.progressRestaurant = Math.round((100 / event.total) * event.loaded);
         if(this.progressRestaurant === 100)
         {
          this.step1 = false;
          this.step2 = false;
          this.step3 = false;
          this.step4 = true;
         }
         
      } else if (event.type == HttpEventType.Response) {
        this.progressRestaurant = null;
      }
    }),
    catchError((err: any) => {
      this.progressRestaurant = null;
      this.notifier.notify("error" , err.message);
      return throwError(err.message);
    })
  )
  .toPromise();
}
  //   this.service.addprofileRestaurant(a).subscribe((res: any) => {
     
  //     if (res.status === 'True') {
  //       this.notifier.notify("info", "Successfully Submitted!");
        
  //       this.spinner.hide();
  //       this.step1 = false;
  //       this.step2 = false;
  //       this.step3 = false;
  //       this.step4 = true;
  //     }
  //     else {
  //       this.spinner.hide();
  //       this.notifier.notify("error", "Something went wrong!")
  //     }

  //   });

  // }

 

  }




  public getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
  }







  public getSingleRestaurantProfile = () => {

    this.service.getSingleRestaurantProfile().subscribe((res: any) => {
     
      if (res.status === "True") {
        if (res.Profile[0].admin_status === "pending" || (res.Profile[0].admin_status === "Approved")) {
          localStorage.setItem('restaurantDashboard', res.Profile[0].admin_status);

        }
        else {
          return;
        }

      }
    });
  }
















  public promoVideoPicker = (event) => {
    let onInnerSection: boolean = false;
    this.promoVideooFile = event.target.files[0];
    this.promoVideoName = event.target.files[0].name;
    let _validFileExtensions = ["video/mp4", "video/3gpp", "video/quicktime"
      , "video/x-ms-wmv", "video/x-msvideo", "video/mpeg"
      , "video/dvd", "video/xvid", "video/x-flv"
      , "video/x-f4v", "video/divx"];

    for (let i = 0; i <= 4; i++) {
      if (_validFileExtensions[i] == event.target.files[0].type) {
        this.promoVideooFile = event.target.files[0];
        let kb = event.target.files[0].size / 1000;
        let mb = kb / 1000;
        if (mb > 30) {
          this.promoVideoName = null;
          this.validVideoPromo = false;
          this.promoVideooFile = null;
          this.promoVideoName = "File must be less than 30 MB";
          return;
        }
        else {
          this.promoVideoName = null;
          onInnerSection = true;
          this.validVideoPromo = true;
          this.promoVideoName = event.target.files[0].name;
        }
      }
      else if (onInnerSection == false) {
        this.promoVideoName = null;
        this.validVideoPromo = false;
        this.promoVideooFile = null;
        this.promoVideoName = "Please Enter Valid File Type!";
      }
    }

  }







  public imgLogoPicker = (event) => {
    let onInnerSection: boolean = false;
    this.imgLogoFile = event.target.files[0];
    this.imgLogoFileName = event.target.files[0].name;
    let _validFileExtensions = ["image/png", "image/PNG"];

    for (let i = 0; i <= 4; i++) {
      if (_validFileExtensions[i] == event.target.files[0].type) {
        this.imgLogoFile = event.target.files[0];
        let kb = event.target.files[0].size / 1000;
        let mb = kb / 1000;
        if (mb > 5) {
          this.imgLogoFileName = null;
          this.validLogoFile = false;
          this.imgLogoFile = null;
          this.imgLogoFileName = "File must be less than 5 MB";
          return;
        }
        else {
          this.imgLogoFileName = null;
          onInnerSection = true;
          this.validLogoFile = true;
          this.imgLogoFileName = event.target.files[0].name;
        }
      }
      else if (onInnerSection == false) {
        this.imgLogoFileName = null;
        this.validLogoFile = false;
        this.imgLogoFile = null;
        this.imgLogoFileName = "Please Enter PNG File Type";
      }
    }
    
 
  }



  public imgBannerPicker = (event) => {
    let onInnerSection: boolean = false;
    this.imgBannerFile = event.target.files[0];
    this.imgBannerFileName = event.target.files[0].name;
    let _validFileExtensions = ["image/jpg", "image/png", "image/jpeg", "image/bmp", "image/gif"];

    for (let i = 0; i <= 4; i++) {
      if (_validFileExtensions[i] == event.target.files[0].type) {
        this.imgBannerFile = event.target.files[0];
        let kb = event.target.files[0].size / 1000;
        let mb = kb / 1000;
        if (mb > 5) {
          this.imgBannerFileName = null;
          this.validBannerFile = false;
          this.imgBannerFile = null;
          this.imgBannerFileName = "File must be less than 5 MB";
          return;
        }
        else {
          this.imgBannerFileName = null;
          onInnerSection = true;
          this.validBannerFile = true;
          this.imgBannerFileName = event.target.files[0].name;
        }
      }
      else if (onInnerSection == false) {
        this.imgBannerFileName = null;
        this.validBannerFile = false;
        this.imgBannerFile = null;
        this.imgBannerFileName = "Please Enter Valid File Type"
      }
    }

 
  }







  public step1Call = () => {
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
    this.step4 = false;
  }




  public step2Call = () => {

    this.submitted = true;

    if (this.addRestaurantForm.controls['restaurant_name'].invalid
      || this.addRestaurantForm.controls['restaurant_email'].invalid
      || this.addRestaurantForm.controls['landline'].invalid
      || this.addRestaurantForm.controls['mobile'].invalid
      || this.addRestaurantForm.controls['fax'].invalid
      || this.addRestaurantForm.controls['address'].invalid
      || this.addRestaurantForm.controls['zipcode'].invalid
      || this.addRestaurantForm.controls['city'].invalid
      || this.addRestaurantForm.controls['state'].invalid) {
      this.step1 = true;
      this.step2 = false;
      this.step3 = false;
      this.step4 = false;
      this.notifier.notify("error", "Some Fields are not Valid!");
    }
    else {
      this.step1 = false;
      this.step2 = true;
      this.step3 = false;
      this.step4 = false;
    }


  }









  public step3Call = () => {

    this.contentSubmitted = true;

    if (this.addRestaurantForm.controls['logoImg'].invalid
      || this.addRestaurantForm.controls['bannerImg'].invalid
      || this.addRestaurantForm.controls['aboutus_content'].invalid
      || this.addRestaurantForm.controls['chef_content'].invalid
      || this.addRestaurantForm.controls['chef_signature'].invalid
      || this.addRestaurantForm.controls['facebook_link'].invalid
      || this.addRestaurantForm.controls['instagram_link'].invalid
      || this.addRestaurantForm.controls['landline_content'].invalid
      || this.addRestaurantForm.controls['mobile_content'].invalid
      || this.addRestaurantForm.controls['email_content'].invalid
      || this.addRestaurantForm.controls['address_content'].invalid
      || this.addRestaurantForm.controls['feature_dish1'].invalid
      || this.addRestaurantForm.controls['feature_dish2'].invalid
      || this.addRestaurantForm.controls['feature_dish3'].invalid
      || this.addRestaurantForm.controls['featureDishPrice1'].invalid
      || this.addRestaurantForm.controls['featureDishPrice2'].invalid
      || this.addRestaurantForm.controls['featureDishPrice3'].invalid
      || this.addRestaurantForm.controls['featureDish1Img'].invalid
      || this.addRestaurantForm.controls['featureDish2Img'].invalid
      || this.addRestaurantForm.controls['featureDish3Img'].invalid
    ) {
      this.step1 = false;
      this.step2 = true;
      this.step3 = false;
      this.step4 = false;
      this.notifier.notify("error", "Some Fields are not Valid!");
    }
    else {
      this.step1 = false;
      this.step2 = false;
      this.step3 = true;
      this.step4 = false;
    }
  }













  public step4Call = () => {
    this.step1 = false;
    this.step2 = false;
    this.step3 = false;
    this.step4 = true;
  }






  // public imgDish1Picker = (event) => {
  //   let onInnerSection: boolean = false;
  //   this.imgSpecial1File = event.target.files[0];
  //   this.imgSpecial1FileName = event.target.files[0].name;
  //   let _validFileExtensions = ["image/jpg", "image/png", "image/jpeg", "image/bmp", "image/gif"];

  //   for (let i = 0; i <= 4; i++) {
  //     if (_validFileExtensions[i] == event.target.files[0].type) {
  //       this.imgSpecial1File = event.target.files[0];
  //       let kb = event.target.files[0].size / 1000;
  //       let mb = kb / 1000;
  //       if (mb > 3) {
  //         this.imgSpecial1FileName = null;
  //         this.validSpecial1File = false;
  //         this.imgSpecial1FileName = "File must be less than 3 MB";
  //         return;
  //       }
  //       else {
  //         this.imgSpecial1FileName = null;
  //         onInnerSection = true;
  //         this.validSpecial1File = true;
  //         this.imgSpecial1FileName = event.target.files[0].name;
  //       }
  //     }
  //     else if (onInnerSection == false) {
  //       this.imgSpecial1FileName = null;
  //       this.validSpecial1File = false;
  //       this.imgSpecial1FileName = "Please Enter Valid File Type"
  //     }
  //   }

 
  // }


  // public imgDish2Picker = (event) => {
  //   let onInnerSection: boolean = false;
  //   this.imgSpecial2File = event.target.files[0];
  //   this.imgSpecial2FileName = event.target.files[0].name;
  //   let _validFileExtensions = ["image/jpg", "image/png", "image/jpeg", "image/bmp", "image/gif"];

  //   for (let i = 0; i <= 4; i++) {
  //     if (_validFileExtensions[i] == event.target.files[0].type) {
  //       this.imgSpecial2File = event.target.files[0];
  //       let kb = event.target.files[0].size / 1000;
  //       let mb = kb / 1000;
  //       if (mb > 3) {
  //         this.imgSpecial2FileName = null;
  //         this.validSpecial2File = false;
  //         this.imgSpecial2FileName = "File must be less than 3 MB";
  //         return;
  //       }
  //       else {
  //         this.imgSpecial2FileName = null;
  //         onInnerSection = true;
  //         this.validSpecial2File = true;
  //         this.imgSpecial2FileName = event.target.files[0].name;
  //       }
  //     }
  //     else if (onInnerSection == false) {
  //       this.imgSpecial2FileName = null;
  //       this.validSpecial2File = false;
  //       this.imgSpecial2FileName = "Please Enter Valid File Type"
  //     }
  //   }

 
  // }


  // public imgDish3Picker = (event) => {
  //   let onInnerSection: boolean = false;
  //   this.imgSpecial3File = event.target.files[0];
  //   this.imgSpecial3FileName = event.target.files[0].name;
  //   let _validFileExtensions = ["image/jpg", "image/png", "image/jpeg", "image/bmp", "image/gif"];

  //   for (let i = 0; i <= 4; i++) {
  //     if (_validFileExtensions[i] == event.target.files[0].type) {
  //       this.imgSpecial3File = event.target.files[0];
  //       let kb = event.target.files[0].size / 1000;
  //       let mb = kb / 1000;
  //       if (mb > 3) {
  //         this.imgSpecial3FileName = null;
  //         this.validSpecial3File = false;
  //         this.imgSpecial3FileName = "File must be less than 3 MB";
  //         return;
  //       }
  //       else {
  //         this.imgSpecial3FileName = null;
  //         onInnerSection = true;
  //         this.validSpecial3File = true;
  //         this.imgSpecial3FileName = event.target.files[0].name;
  //       }
  //     }
  //     else if (onInnerSection == false) {
  //       this.imgSpecial3FileName = null;
  //       this.validSpecial3File = false;
  //       this.imgSpecial3FileName = "Please Enter Valid File Type"
  //     }
  //   }

   
  // }


  // public imgDish4Picker = (event) => {
  //   let onInnerSection: boolean = false;
  //   this.imgSpecial4File = event.target.files[0];
  //   this.imgSpecial4FileName = event.target.files[0].name;
  //   let _validFileExtensions = ["image/jpg", "image/png", "image/jpeg", "image/bmp", "image/gif"];

  //   for (let i = 0; i <= 4; i++) {
  //     if (_validFileExtensions[i] == event.target.files[0].type) {
  //       this.imgSpecial4File = event.target.files[0];
  //       let kb = event.target.files[0].size / 1000;
  //       let mb = kb / 1000;
  //       if (mb > 3) {
  //         this.imgSpecial4FileName = null;
  //         this.validSpecial4File = false;
  //         this.imgSpecial4FileName = "File must be less than 3 MB";
  //         return;
  //       }
  //       else {
  //         this.imgSpecial4FileName = null;
  //         onInnerSection = true;
  //         this.validSpecial4File = true;
  //         this.imgSpecial4FileName = event.target.files[0].name;
  //       }
  //     }
  //     else if (onInnerSection == false) {
  //       this.imgSpecial4FileName = null;
  //       this.validSpecial2File = false;
  //       this.imgSpecial4FileName = "Please Enter Valid File Type"
  //     }
  //   }

  
  // }


  // public imgDish5Picker = (event) => {
  //   let onInnerSection: boolean = false;
  //   this.imgSpecial5File = event.target.files[0];
  //   this.imgSpecial5FileName = event.target.files[0].name;
  //   let _validFileExtensions = ["image/jpg", "image/png", "image/jpeg", "image/bmp", "image/gif"];

  //   for (let i = 0; i <= 4; i++) {
  //     if (_validFileExtensions[i] == event.target.files[0].type) {
  //       this.imgSpecial5File = event.target.files[0];
  //       let kb = event.target.files[0].size / 1000;
  //       let mb = kb / 1000;
  //       if (mb > 3) {
  //         this.imgSpecial5FileName = null;
  //         this.validSpecial5File = false;
  //         this.imgSpecial5FileName = "File must be less than 3 MB";
  //         return;
  //       }
  //       else {
  //         this.imgSpecial5FileName = null;
  //         onInnerSection = true;
  //         this.validSpecial5File = true;
  //         this.imgSpecial5FileName = event.target.files[0].name;
  //       }
  //     }
  //     else if (onInnerSection == false) {
  //       this.imgSpecial5FileName = null;
  //       this.validSpecial2File = false;
  //       this.imgSpecial5FileName = "Please Enter Valid File Type"
  //     }
  //   }

 
  // }



  public imgFeature1Picker = (event) => {
    let onInnerSection: boolean = false;
    this.imgFeature1File = event.target.files[0];
    this.imgFeature1FileName = event.target.files[0].name;
    let _validFileExtensions = ["image/jpg", "image/png", "image/jpeg", "image/bmp", "image/gif"];

    for (let i = 0; i <= 4; i++) {
      if (_validFileExtensions[i] == event.target.files[0].type) {
        this.imgFeature1File = event.target.files[0];
        let kb = event.target.files[0].size / 1000;
        let mb = kb / 1000;
        if (mb > 5) {
          this.imgFeature1FileName = null;
          this.validFeature1File = false;
          this.imgFeature1File = null;
          this.imgFeature1FileName = "File must be less than 5 MB";
          return;
        }
        else {
          this.imgFeature1FileName = null;
          onInnerSection = true;
          this.validFeature1File = true;
          this.imgFeature1FileName = event.target.files[0].name;
        }
      }
      else if (onInnerSection == false) {
        this.imgFeature1FileName = null;
        this.validFeature1File = false;
        this.imgFeature1File = null;
        this.imgFeature1FileName = "Please Enter Valid File Type"
      }
    }

 
  }


  public imgFeature2Picker = (event) => {
    let onInnerSection: boolean = false;
    this.imgFeature2File = event.target.files[0];
    this.imgFeature2FileName = event.target.files[0].name;
    let _validFileExtensions = ["image/jpg", "image/png", "image/jpeg", "image/bmp", "image/gif"];

    for (let i = 0; i <= 4; i++) {
      if (_validFileExtensions[i] == event.target.files[0].type) {
        this.imgFeature2File = event.target.files[0];
        let kb = event.target.files[0].size / 1000;
        let mb = kb / 1000;
        if (mb > 5) {
          this.imgFeature2FileName = null;
          this.validFeature2File = false;
          this.imgFeature2File = null;
          this.imgFeature2FileName = "File must be less than 5 MB";
          return;
        }
        else {
          this.imgFeature2FileName = null;
          onInnerSection = true;
          this.validFeature2File = true;
          this.imgFeature2FileName = event.target.files[0].name;
        }
      }
      else if (onInnerSection == false) {
        this.imgFeature2FileName = null;
        this.validFeature2File = false;
        this.imgFeature2File = null;
        this.imgFeature2FileName = "Please Enter Valid File Type"
      }
    }

 
  }


  public imgFeature3Picker = (event) => {
    let onInnerSection: boolean = false;
    this.imgFeature3File = event.target.files[0];
    this.imgFeature3FileName = event.target.files[0].name;
    let _validFileExtensions = ["image/jpg", "image/png", "image/jpeg", "image/bmp", "image/gif"];

    for (let i = 0; i <= 4; i++) {
      if (_validFileExtensions[i] == event.target.files[0].type) {
        this.imgFeature3File = event.target.files[0];
        let kb = event.target.files[0].size / 1000;
        let mb = kb / 1000;
        if (mb > 5) {
          this.imgFeature3FileName = null;
          this.validFeature3File = false;
          this.imgFeature3File = null;
          this.imgFeature3FileName = "File must be less than 5 MB";
          return;
        }
        else {
          this.imgFeature3FileName = null;
          onInnerSection = true;
          this.validFeature3File = true;
          this.imgFeature3FileName = event.target.files[0].name;
        }
      }
      else if (onInnerSection == false) {
        this.imgFeature3FileName = null;
        this.validFeature3File = false;
        this.imgFeature3File = null;
        this.imgFeature3FileName = "Please Enter Valid File Type"
      }
    }

 
  }




}
