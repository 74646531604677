<!-- As a heading -->
<nav class="navbar navbar-light navbarClr sticky-top">
  <span class="navbar-brand mb-0 h1"><img src="/assets/logoRed.png" style="height: 60px;" /></span>
  <span class="navbar-brand mb-0 text-light" style="cursor: pointer;" (click)="goToCart()"><i
      class="fas fa-shopping-cart"></i> Back to Cart</span>
</nav>




<div class="container-fluid LeafBG ">
  <div class="container">
    <div class="row">

      <!------------------------------------------------------------------->
      <!----------------------- Delivery Details --------------------------->
      <!-------------------------------------------------------------------->
      <div class="col-md-12 col-lg-8 my-4">
        <div class="card-body borderClr">
          <div class="d-flex">
            <span class="stepNumber">1</span>
            <span class="deliveryDetailsText">Delivery Details</span>
          </div>

          <hr class="cardHead">
          <div class="row">
            <div class="col-md-12">
              <h4 class="text-light text-center">Delivery Type</h4>
            </div>
            <div class="col-md-12 mb-3">
              <select class="form-control deliverytype">
                <option value="PRIORITY DELIVERY">PRIORITY DELIVERY</option>
                <option value="NO RUSH DELIVERY">NO RUSH DELIVERY</option>
                <option value="STANDARD DELIVERY">STANDARD DELIVERY</option>
              </select>
            </div>
            <div class="col-md-12">
              <h4 class="text-light text-center">Delivery Address</h4>
            </div>
            <div class="col-md-12">
              <input type="text" class="form-control deliverytype mb-2" placeholder="Address" />
              <!-- <input ngx-google-places-autocomplete [options]="{
                types: [],
                componentRestrictions: { country: 'UA' }
                }" #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"/> -->
              <div id="map"></div>
            </div>
            <div class="col-md-12 my-3">
              <textarea class="form-control deliverytype" rows="3"
                placeholder="Note to Rider, e.g. direction / landmark"></textarea>
            </div>

          </div>
        </div>

        <div class="card-body borderClr mt-3">
          <div class="d-flex">
            <span class="stepNumber">2</span>
            <span class="deliveryDetailsText">Personal Details</span>
          </div>
          <hr class="cardHead">
          <div class="row">
            <div class="col-md-12 d-flex justify-content-between">
              <div>
                <span class="text-light peronsalName">Cheetah User</span><br>
                <span class="peronsalemailPhone">user@gmail.com</span><br>
                <span class="peronsalemailPhone">+41 241 241 242</span><br>
              </div>
              <a *ngIf="!isEditPersonal" class="editPerosnal" (click)="isEdit()"><i class="fas fa-pen"></i> Edit</a>
            </div>
            <div class="col-md-12 my-4" *ngIf="isEditPersonal">
              <div class="row">
                <div class="col-md-12 col-lg-12">
                  <div class="card-body borderClr">
                    <div class="container">
                      <form>
                        <div class="row">
                          <div class="col-md-12 col-lg-12 form-group">
                            <label>Name</label>
                            <input type="text" class="form-control deliverytype" [value]="personalName"
                              [(ngModel)]="personalName" name="name" />
                            <!-- <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                              <div *ngIf="f.name.errors.required">Name is required</div>
                          </div> -->
                          </div>
                          <div class="col-md-6 col-lg-6 form-group">
                            <label>Email</label>
                            <input type="email" class="form-control deliverytype" [value]="personalEmail"
                              [(ngModel)]="personalEmail" name="email" />
                            <!-- <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                              <div *ngIf="f.email.errors.required">Email is required</div>
                              <div *ngIf="f.email.errors.email">Email is not valid</div>
                          </div> -->
                          </div>
                          <div class="col-md-6 col-lg-6 form-group">
                            <label>Contact #</label>
                            <input type="tel" class="form-control deliverytype" [value]="personalContact" [(ngModel)]="personalContact" name="contact" />
                          </div>
                          <div class="col-md-12 col-lg-12 text-center mt-3">
                            <button class="btn btnAdd btn-sm" (click)="savePersonalEdit()">Save <i
                                class="fa fa-save"></i></button>
                          </div>

                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="card-body borderClr mt-3">
          <div class="d-flex">
            <span class="stepNumber">3</span>
            <span class="deliveryDetailsText">Payment Details</span>
          </div>
          <hr class="cardHead">

          <div class="row">
            <div class="col-md-12">
              <form>
                <div class="container">
                  <div class="row">
                    <div class="col-md-12 borderClr payment-type" *ngIf="isAddedCard">
                      <div class="d-flex justify-content-between">
                        <div class="radio">
                          <input id="addedCard" name="paymentType" (change)="paymentMethod($event.target.value)"
                            type="radio" value="addedCard">
                          <label for="addedCard" class="radio-label">Visa***********12345</label>
                        </div>
                        <img src="/assets/ravian-food/payment-form/visa.png" class="paymentLogos" />
                      </div>

                    </div>
                    <div class="col-md-12 borderClr payment-type my-2">
                      <div class="d-flex justify-content-between">
                        <div class="radio">
                          <input id="addCard" name="paymentType" (change)="paymentMethod($event.target.value)"
                            type="radio" value="addCard">
                          <label for="addCard" class="radio-label">Add Debit / Credit Card</label>
                        </div>
                        <div>
                          <img src="/assets/ravian-food/payment-form/visa.png" class="paymentLogos mx-2" />
                          <img src="/assets/ravian-food/payment-form/6914119.png" class="paymentLogos" />

                        </div>
                      </div>

                    </div>
                    <div class="col-md-12" *ngIf="isAddCard">
                      <div class="container py-4">
                        <div class="row">
                          <div class="col-md-2"> </div>
                          <div class="col-md-8">
                            <div class="card borderClr">
                              <div class="card-header text-center py-3" style="border-bottom: 1px dotted #f02e2e;">
                                <span class="titleAdd">Add Credit / Debit Card</span>
                              </div>
                              <div class="card-body">
                                <form [formGroup]="cardNumberGroup" class="paymentForm">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="row">
                                        <div class="col-md-1"></div>
                                        <div class="col-md-10">
                                          <div class="form-group">
                                            <label>
                                              Card Number
                                            </label>
                                            <input [formControl]="getCardNumberControl()"
                                              [textMask]="{mask: cardMaskFunction, guide: false, showMask: true}"
                                              placeholder="**** **** **** ****" class="input1">

                                            <div *ngIf="getCardNumberControl().dirty">
                                              <p [class.invalid]="getCardNumberControl().invalid"></p>
                                              <p [class.valid]="getCardNumberControl().valid"></p>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-md-1"></div>
                                      </div>

                                    </div>
                                    <div class="col-md-12">
                                      <div class="row">

                                        <div class="col-md-1">

                                        </div>
                                        <div class="col-md-5">
                                          <div class="form-group">
                                            <label>Exp Date</label>
                                            <input maxlength='5' type="text" (keyup)="formatString($event);"
                                              placeholder="MM/YY" formControlName="expiryDate" class="input1" />
                                          </div>
                                        </div>
                                        <div class="col-md-5">
                                          <div class="form-group">
                                            <label>CVV</label>
                                            <input type="tel" maxlength="4" minlength="3" placeholder="***"
                                              formControlName="cvvNumber" class="input1" />
                                          </div>
                                        </div>
                                        <div class="col-md-1">

                                        </div>

                                      </div>
                                      <div class="row mt-1">
                                        <div class="col-md-1"></div>
                                        <div class="col-md-10">
                                          <div class="form-group">
                                            <input class="styled-checkbox" id="styled-checkbox-1" type="checkbox"
                                              value="save_card">
                                            <label for="styled-checkbox-1">Save my card</label>
                                          </div>
                                        </div>
                                        <div class="col-md-1"></div>
                                      </div>

                                    </div>
                                    <div class="col-md-12 text-center mt-1">
                                      <button class="btn btnAdd btn-sm" (click)="addCard()">Add <i
                                          class="fa fa-plus"></i></button>
                                    </div>

                                  </div>

                                </form>
                              </div>

                            </div>
                          </div>
                          <div class="col-md-2"> </div>

                        </div>


                      </div>
                  
                    </div>
                    <div class="col-md-12 borderClr payment-type">
                      <div class="d-flex justify-content-between">
                        <div class="radio">
                          <input id="Wallet" name="paymentType" (change)="paymentMethod($event.target.value)"
                            type="radio" value="Wallet">
                          <label for="Wallet" class="radio-label">Cheetah Wallet</label>
                        </div>
                        <img src="/assets/ravian-food/payment-form/purse.png" class="paymentCash" />
                      </div>

                    </div>
                    <div class="col-md-12 borderClr payment-type">
                      <div class="d-flex justify-content-between">
                        <div class="radio">
                          <input id="cash" name="paymentType" (change)="paymentMethod($event.target.value)" type="radio"
                            value="Cash" checked>
                          <label for="cash" class="radio-label">Cash</label>
                        </div>
                        <img src="/assets/ravian-food/payment-form/cash.png" class="paymentCash" />
                      </div>

                    </div>
                  </div>
                  <div class="col-md-12 mt-4">
                    <p class="text-left" style="color: gray;">By making this purchase you agree to our <strong
                        style="color: #f02e2e;"><a>Terms & Conditions</a></strong></p>
                  </div>
                  <div class="col-md-12 mt-2">
                    <p class="text-left" style="color: gray;">Get Discount,<strong style="color: #f02e2e;"><a
                          (click)="showPromo()"> Add Promo Code if you have</a></strong></p>
                    <div class="row borderClr" *ngIf="isPromo">
                      <div class="col-md-12">
                        <form class="p-4" [formGroup]="promoForm">
                          <div class="form-inline">
                            <label class="m-2">Promo Code</label>
                            <input class="form-control deliverytype m-2" type="text" formControlName="promoInput" />
                            <button type="button" class="btn btnAdd btn-sm m-2" (click)="addPromo()">Add <i
                                class="fa fa-plus"></i></button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-4">
                    <div class="placeOrder" (click)="placeOrder()">
                      Place Order
                    </div>
                  </div>
                  <div class="col-md-12 mt-4">
                    <p class="termConditionsText">I agree and I demand that you execute the order service before the end
                      of revocation period. I am aware that after complete fulfillment of the service I lose my right of
                      recission. Payment transactions will be processed aboad.</p>
                  </div>

                </div>

              </form>

            </div>

          </div>

        </div>


      </div>
      <!------------------------------------------------------------------->
      <!----------------------- Delivery Details --------------------------->
      <!-------------------------------------------------------------------->



      <!----------------------------------------------------------------->
      <!----------------------- Order Details --------------------------->
      <!----------------------------------------------------------------->
      <div class="col-md-12 col-lg-4 p-0">
        <div class="card-body">
          <!-- <span class="deliveryDetailsText">Order Details</span>
                  <hr class="cardHead"> -->
          <!-- <div class="row" *ngFor="let data of cartItems">
                          <div class="col-md-7 col-lg-7 col-sm-7 col-xs-7">
                            <div class="row"  >
                                 <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4">  <p class="text-light"><b>{{data.quantity}}</b>&nbsp;&nbsp;&nbsp;&nbsp; <i class="fas fa-times"></i></p> </div>
                                 <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8"><p class="text-light">{{data.product_name}}</p></div>
                            </div>
                             
                          </div>
                          <div class="col-md-5 col-md-5 col-sm-5 col-xs-5">
                                   <div class="row">
                                         <div class="col-md-3 col-lg-3 col-sm-3 col-xs-3">
                                               <p class="text-light"><i class="fas fa-equals"></i></p>
                                         </div>
                                         <div class="col-md-9 col-lg-9 col-sm-9 col-xs-9">
                                                 <p class="text-light">$ {{data.calcPrice}}</p>
                                        </div>
                                   </div>
                          </div>  
                  </div> -->
          <div class="receipt mb-3" style="margin-top: 4px;">
            <header class="receipt__header">
              <p class="receipt__title">
                Order Summary
              </p>
              <p class="receipt__date">{{date}}</p>
            </header>
            <dl class="receipt__list">
              <div class="receipt__list-row" *ngFor="let data of cartItems">
                <dt class="receipt__item">{{data.quantity}}&nbsp;&nbsp; <i class="fas fa-times"></i>
                  &nbsp;&nbsp;{{data.product_name}} </dt>
                <dd class="receipt__cost">{{data.calcPrice}} CHF</dd>
              </div>
              <div class="receipt__list-row receipt__list-row--total">
                <dt class="receipt__item"><small>Subtotal</small></dt>
                <dd class="receipt__cost"><small>{{totalPrice}}.00 CHF</small></dd>
              </div>
              <div class="receipt__list-row receipt__list-row--total1">
                <dt class="receipt__item"><small>Delivery Fee</small></dt>
                <dd class="receipt__cost"><small>0.00 CHF</small></dd>
              </div>
              <div class="receipt__list-row receipt__list-row--total1">
                <dt class="receipt__item">Total <small>(Incl. GST)</small></dt>
                <dd class="receipt__cost">{{totalPrice}}.00 CHF</dd>
              </div>
            </dl>

          </div>


        </div>

      </div>
      <!----------------------------------------------------------------->
      <!----------------------- Order Details --------------------------->
      <!----------------------------------------------------------------->


    </div>

  </div>
</div>


<!------------Footer Start-------------->
<app-cheetay-footer></app-cheetay-footer>
<!--------------Footer End-------------->