import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddCateringComponent } from './add-catering/add-catering.component';
import { AddRestaurantComponent } from './add-restaurant/add-restaurant.component';
import { CartComponent } from './cart/cart.component';
import { CateringGuradService } from './catering-gurad.service';
import { CheckoutLoginComponent } from './checkout-login/checkout-login.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RestaurantGuardService } from './restaurant-guard.service';
import { ReviewOrderComponent } from './review-order/review-order.component';
import { SearchPageComponent } from './search-page/search-page.component';
import { UserLoginGuardService } from './user-login-guard.service';



const routes: Routes = [

  { path: '', redirectTo: 'home-cheetah', pathMatch: 'full' },
   {path : 'login' , component : LoginComponent},
   {path : 'add-catering' , component : AddCateringComponent , canActivate:  [CateringGuradService]},
   {path : 'add-restaurant' , component : AddRestaurantComponent , canActivate:  [RestaurantGuardService] },
   {path : 'forget-password' , component : ForgetPasswordComponent},
   {path : 'cart' , component : CartComponent},
   {path : 'checkout-login' , component : CheckoutLoginComponent , canActivate : [UserLoginGuardService]},
   {path : 'review-order' , component : ReviewOrderComponent},
   {path : 'search' , component : SearchPageComponent},
  {
    path: 'home-cheetah',
    loadChildren : () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'about-cheetah',
    loadChildren: () => import('./about/about.module').then(m => m.AboutModule)
  },
  {
    path: 'contact-cheetah',
    loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: 'catering-cheetah',
    loadChildren: () => import('./catering/catering.module').then(m => m.CateringModule)
  },
  {
    path: 'menu-cheetah',
    loadChildren: () => import('./menu/menu.module').then(m => m.MenuModule)
  },
  {
    path: 'home-cheetah/ninja-Sushi',
    loadChildren: () => import('./ninja-sushi/ninja-sushi.module').then(m => m.NinjaSushiModule),
    // canActivate:  [RestaurantGuardService],
  },
  {
    path: 'home-cheetah/catering-provider',
    loadChildren: () => import('./catering-provider/catering-provider.module').then(m => m.CateringProviderModule),
    // canActivate:  [CateringGuradService],
  },
  {
    path: 'home-cheetah/featured-menu',
    loadChildren: () => import('./featured-menu/featured-menu.module').then(m => m.FeaturedMenuModule),
    // canActivate:  [CateringGuradService],
  },
  {
    path: 'admin-catering',
    loadChildren: () => import('./catering-admin/catering-admin.module').then(m => m.CateringAdminModule),
    canActivate:  [CateringGuradService],
  },
  {
    path: 'admin-restaurant',
    loadChildren: () => import('./restaurant-admin/restaurant-admin.module').then(m => m.RestaurantAdminModule),
    canActivate:  [RestaurantGuardService],
  },

  { path: '**', component: PageNotFoundComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes , { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


