<div class="container-fluid loginPageBg LeafBG">
<notifier-container></notifier-container>
  <div class="container">
    <section class="mainForm">
      <div class="container-fluid mt-3">
        <div class="text-center">
          <img src="/assets/favicon.png" height="80px" /><br>
          <div class="progress mx-5" *ngIf="progressCatering">
            <div class="progress-bar" [style.width]="progressCatering + '%'">{{progressCatering}} %</div>
          </div>
        </div>
        <div class="row mt-5 d-flex justify-content-center">
          <div class="col-md-10">
            <div class="wizard">
              <div class="wizard-inner">
                <div class="connecting-line"></div>
                <ul class="nav nav-tabs" role="tablist">
                  <li role="presentation" [class.active]="step1">
                    <a><span class="round-tab">1 </span> <i>Step 1</i></a>
                  </li>
                  <li role="presentation" [class.active]="step2">
                    <a><span class="round-tab">2</span> <i>Step 2</i></a>
                  </li>
                  <li role="presentation" [class.active]="step3">
                    <a><span class="round-tab">3</span>
                      <i>Step 3</i></a>
                  </li>
                </ul>
              </div>
              <form [formGroup]="addCateringForm" class="login-box">
                <div class="tab-content" id="main_form">
                  <!-- Company Details Start -->
                  <div class="tab-pane" [class.active]="step1" role="tabpanel" id="step1">
                    <h2 class="text-center">Catering Company Details</h2>

                    <div class="content mt-5 pb-4">
                      <div class="titlebox">Company Details</div>
                      <div class="row mt-4" style="row-gap: 10px;">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="company_name"><b>Company Name</b></label> 
                            <input class="form-control" formControlName="company_name" type="text" required [ngClass]="{ 'is-invalid': submitted && f.company_name.errors }"/>
                            <div *ngIf="submitted && f.company_name.errors" class="invalid-feedback">
                              <div *ngIf="f.company_name.errors.required">Company Name is required</div>
                          </div>   
                             
                           
                          </div>
                         
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="company_email"><b>Company Email</b></label>
                            <input class="form-control" formControlName="company_email" type="email" required [ngClass]="{ 'is-invalid': submitted && f.company_email.errors }"/>
                            <div *ngIf="submitted && f.company_email.errors" class="invalid-feedback">
                              <div *ngIf="f.company_email.errors.required">Company Email is required</div>
                              <div *ngIf="f.company_email.errors.email">Email is not Valid</div>
                          </div>   
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label  for="company_landline"><b>Landline #</b></label>
                            <input class="form-control" formControlName="landline" type="tel" required [ngClass]="{ 'is-invalid': submitted && f.landline.errors }"/>
                            <div *ngIf="submitted && f.landline.errors" class="invalid-feedback">
                              <div *ngIf="f.landline.errors.required">Landline # is required</div>
                          </div> 
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label  for="company_mobile"><b>Mobile #</b></label>
                            <input class="form-control" formControlName="mobile" type="tel" required  [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }"/>
                            <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                              <div *ngIf="f.mobile.errors.required">Mobile # is required</div>
                          </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label  for="company_fax"><b>Fax #</b></label>
                            <input class="form-control" formControlName="fax" type="tel" required [ngClass]="{ 'is-invalid': submitted && f.fax.errors }"/>
                            <div *ngIf="submitted && f.fax.errors" class="invalid-feedback">
                              <div *ngIf="f.fax.errors.required">Fax # is required</div>
                          </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label  for="company_mobile"><b>Address</b></label>
                            <textarea class="form-control" formControlName="address" type="text" required [ngClass]="{ 'is-invalid': submitted && f.address.errors }"></textarea>
                            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                              <div *ngIf="f.address.errors.required">Address is required</div>
                          </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label  for="company_mobile"><b>Zip Code</b></label>
                            <input class="form-control" formControlName="zipcode" type="text" required [ngClass]="{ 'is-invalid': submitted && f.zipcode.errors }"/>
                            <div *ngIf="submitted && f.zipcode.errors" class="invalid-feedback">
                              <div *ngIf="f.zipcode.errors.required">Zipcode is required</div>
                          </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="company_mobile"><b>City</b></label>
                            <input class="form-control" formControlName="city" type="text" required [ngClass]="{ 'is-invalid': submitted && f.city.errors }"/>
                            <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                              <div *ngIf="f.city.errors.required">City is required</div>
                          </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="company_mobile"><b>State</b></label>
                            <input class="form-control" formControlName="state" type="text" required [ngClass]="{ 'is-invalid': submitted && f.state.errors }"/>
                            <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                              <div *ngIf="f.state.errors.required">State is required</div>
                          </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div id="map"></div>
                        </div>


                      </div>
                    </div>

                    <ul class="list-inline pull-right mt-5">
                      <li><a class="btn btn-Next" (click)="step2Call()" data-toggle="tab"
                          aria-controls="step2" role="tab" aria-expanded="false">Continue to next step</a></li>
                    </ul>
                  </div>
                  <!-- Company Details End -->

                  <!-- Content Details Start -->
                  <div class="tab-pane" [class.active]="step2" role="tabpanel" id="step2">
                    <h2 class="text-center mb-5">Web Content</h2>
                    <!-- Logo Start -->
                    <div class="content my-3">
                      <div class="titlebox">Logo Upload</div>
                      <div class="row">
                        <div class="form-group col-md-12 my-4">
                          <label for="logoImg"><b>Logo Image</b></label>
                          <input type="file" formControlName="logoImg" (change)="imgLogoPicker($event)" #logoFile
                            class="form-control-file" id="exampleFormControlFile1" [ngClass]="{'is-invalid': contentSubmitted && f.logoImg.errors}">
                            <div *ngIf="contentSubmitted && f.logoImg.errors" class="invalid-feedback">
                              <div *ngIf="f.logoImg.errors.required">Logo Image is required</div>
                          </div>
                          <span class="text-danger" *ngIf="validLogoFile!=true">{{imgLogoFileName}} </span>
                        </div>
                      </div>
                    </div>

                    <!-- Logo End -->

                    <!-- Banner  Start -->
                    <div class="content my-3">
                      <div class="titlebox">Banner Content</div>
                      <div class="row my-3">
                        <div class="col-md-7">
                          <div class="form-group">
                            <label for="banner_content"><b>Banner Content</b></label>
                            <textarea class="form-control" formControlName="banner_content" type="text"
                              required [ngClass]="{ 'is-invalid': contentSubmitted && f.banner_content.errors }"></textarea>
                              <div *ngIf="contentSubmitted && f.banner_content.errors" class="invalid-feedback">
                                <div *ngIf="f.banner_content.errors.required">Banner Content is required</div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-md-5 mt-2">
                          <label for="bannerImg"><b>Banner Image</b></label>
                          <input type="file" formControlName="bannerImg" (change)="imgBannerPicker($event)"
                            #bannerImgFile class="form-control-file" id="exampleFormControlFile1" [ngClass]="{ 'is-invalid': contentSubmitted && f.bannerImg.errors }">
                            <div *ngIf="contentSubmitted && f.bannerImg.errors" class="invalid-feedback">
                              <div *ngIf="f.bannerImg.errors.required">Banner Image is required</div>
                          </div>
                          <span class="text-danger" *ngIf="validBannerFile!=true">{{imgBannerFileName}} </span>
                        </div>
                      </div>
                    </div>
                    <!-- Banner Us End -->


                    <!-- About us Start -->
                    <div class="content my-4">
                      <div class="titlebox">About us Content</div>
                      <div class="row my-3">
                        <div class="col-md-7">
                          <div class="form-group">
                            <label   for="aboutus_content"><b>About Us Content</b></label>
                            <textarea class="form-control" formControlName="aboutus_content" type="text"
                              required [ngClass]="{ 'is-invalid': contentSubmitted && f.aboutus_content.errors }"></textarea>
                              <div *ngIf="contentSubmitted && f.aboutus_content.errors" class="invalid-feedback">
                                <div *ngIf="f.aboutus_content.errors.required">About us content is required</div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-md-5 mt-2">
                          <label for="aboutusImg"><b>About us Image</b></label>
                          <input type="file" formControlName="aboutusImg" (change)="imgAboutUsPicker($event)"
                            #aboutusImgFile class="form-control-file" id="exampleFormControlFile1" [ngClass]="{ 'is-invalid': contentSubmitted && f.aboutusImg.errors }">
                            <div *ngIf="contentSubmitted && f.aboutusImg.errors" class="invalid-feedback">
                              <div *ngIf="f.aboutusImg.errors.required">About us Image is required</div>
                          </div>
                          <span class="text-danger" *ngIf="validAboutusFile!=true">{{imgAboutusFileName}} </span>
                        </div>
                      </div>
                    </div>

                    <!-- About Us End -->

                    <!-- Social Media Links Start -->
                    <div class="content my-4">
                      <div class="titlebox">Social Media Links</div>
                      <div class="row my-3">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="facebook_link"><b>Facebook Link</b></label>
                            <input class="form-control" formControlName="facebook_link" type="text" required [ngClass]="{ 'is-invalid': contentSubmitted && f.facebook_link.errors }"/>
                            <div *ngIf="contentSubmitted && f.facebook_link.errors" class="invalid-feedback">
                              <div *ngIf="f.facebook_link.errors.required">Facebook Link is required</div>
                          </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label   for="instagram_link"><b>Instagram Link</b></label>
                            <input class="form-control" formControlName="instagram_link" type="text" required [ngClass]="{ 'is-invalid': contentSubmitted && f.instagram_link.errors }"/>
                            <div *ngIf="contentSubmitted && f.instagram_link.errors" class="invalid-feedback">
                              <div *ngIf="f.instagram_link.errors.required">Instagram Link is required</div>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Social Media Links End -->


                    <!-- Contact Links Start -->
                    <div class="content my-4">
                      <div class="titlebox">Contact Details</div>
                      <div class="row my-3">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label  for="landline_content"><b>Landline #</b></label>
                            <input class="form-control" formControlName="landline_content" type="tel" required [ngClass]="{ 'is-invalid': contentSubmitted && f.landline_content.errors }"/>
                            <div *ngIf="contentSubmitted && f.landline_content.errors" class="invalid-feedback">
                              <div *ngIf="f.landline_content.errors.required">Landline # is required</div>
                          </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label  for="mobile_content"><b>Mobile #</b></label>
                            <input class="form-control" formControlName="mobile_content" type="tel" required [ngClass]="{ 'is-invalid': contentSubmitted && f.mobile_content.errors }"/>
                            <div *ngIf="contentSubmitted && f.mobile_content.errors" class="invalid-feedback">
                              <div *ngIf="f.mobile_content.errors.required">Mobile # is required</div>
                          </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label  for="email_content"><b>Email</b></label>
                            <input class="form-control" formControlName="email_content" type="email" required [ngClass]="{ 'is-invalid': contentSubmitted && f.email_content.errors }"/>
                            <div *ngIf="contentSubmitted && f.email_content.errors" class="invalid-feedback">
                              <div *ngIf="f.email_content.errors.required">Email is required</div>
                              <div *ngIf="f.email_content.errors.email">Email is not valid</div>
                          </div>
                          </div>


                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label   for="address_content"><b>Address</b></label>
                            <textarea class="form-control" formControlName="address_content" type="text"
                              required [ngClass]="{ 'is-invalid': contentSubmitted && f.address_content.errors }"></textarea>
                              <div *ngIf="contentSubmitted && f.address_content.errors" class="invalid-feedback">
                                <div *ngIf="f.address_content.errors.required">Address is required</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Contact Links End -->

                    <!-- Vision Start -->
                    <div class="content my-4">
                      <div class="titlebox">Vision Text</div>
                      <div class="row my-3">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label   for="vision_content"><b>Vision Text</b></label>
                            <textarea class="form-control" formControlName="vision_content" type="text"
                              required [ngClass]="{ 'is-invalid': contentSubmitted && f.vision_content.errors }"></textarea>
                              <div *ngIf="contentSubmitted && f.vision_content.errors" class="invalid-feedback">
                                <div *ngIf="f.vision_content.errors.required">Vision Text is required</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Vision End -->

                    <!-- Staff Details Start -->
                    <div class="content my-4">
                      <div class="titlebox">4 Staff Members Details</div>

                      <div class="row my-3">

                        <div class="col-md-4">
                          <div class="form-group">
                            <label  for="staff_name1"><b>Name</b></label>
                            <input class="form-control" formControlName="staff_name1" type="text" required [ngClass]="{ 'is-invalid': contentSubmitted && f.staff_name1.errors }"/>
                            <div *ngIf="contentSubmitted && f.staff_name1.errors" class="invalid-feedback">
                              <div *ngIf="f.staff_name1.errors.required">Name is required</div>
                          </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label  for="staff_designation1"><b>Designation</b></label>
                            <input class="form-control" formControlName="staff_designation1" type="text" required  [ngClass]="{ 'is-invalid': contentSubmitted && f.staff_designation1.errors }"/>
                            <div *ngIf="contentSubmitted && f.staff_designation1.errors" class="invalid-feedback">
                              <div *ngIf="f.staff_designation1.errors.required">Designation is required</div>
                          </div>
                          </div>
                        </div>
                        <div class="form-group col-md-4 mt-1">
                          <label for="staff1Img"><b>Staff Image 1</b></label>
                          <input type="file" formControlName="staff1Img" (change)="imgStaff1Picker($event)"
                            #staff1ImgFile class="form-control-file" id="exampleFormControlFile1" [ngClass]="{ 'is-invalid': contentSubmitted && f.staff1Img.errors }">
                            <div *ngIf="contentSubmitted && f.staff1Img.errors" class="invalid-feedback">
                              <div *ngIf="f.staff1Img.errors.required">Staff Image is required</div>
                          </div>
                          <span class="text-danger" *ngIf="validStaff1File!=true">{{imgStaff1FileName}} </span>
                          </div>

                        <div class="col-md-4">
                          <div class="form-group">
                            <label  for="staff_name2"><b>Name</b></label>
                            <input class="form-control" formControlName="staff_name2" type="text" required [ngClass]="{ 'is-invalid': contentSubmitted && f.staff_name2.errors }"/>
                            <div *ngIf="contentSubmitted && f.staff_name2.errors" class="invalid-feedback">
                              <div *ngIf="f.staff_name2.errors.required">Name is required</div>
                          </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="staff_designation2"><b>Designation</b></label>
                            <input class="form-control" formControlName="staff_designation2" type="text" required [ngClass]="{ 'is-invalid': contentSubmitted && f.staff_designation2.errors }"/>
                            <div *ngIf="contentSubmitted && f.staff_designation2.errors" class="invalid-feedback">
                              <div *ngIf="f.staff_designation2.errors.required">Designation is required</div>
                          </div>
                          </div>
                        </div>
                        <div class="form-group col-md-4 mt-1">
                          <label for="staff2Img"><b>Staff Image 2</b></label>
                          <input type="file" formControlName="staff2Img" (change)="imgStaff2Picker($event)"
                            #staff2ImgFile class="form-control-file" id="exampleFormControlFile1" [ngClass]="{ 'is-invalid': contentSubmitted && f.staff2Img.errors }">
                            <div *ngIf="contentSubmitted && f.staff2Img.errors" class="invalid-feedback">
                              <div *ngIf="f.staff2Img.errors.required">Staff Image is required</div>
                          </div>
                          <span class="text-danger" *ngIf="validStaff2File!=true">{{imgStaff2FileName}} </span>
                          </div>

                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="staff_name3"><b>Name</b></label>
                            <input class="form-control" formControlName="staff_name3" type="text" required [ngClass]="{ 'is-invalid': contentSubmitted && f.staff_name3.errors }"/>
                            <div *ngIf="contentSubmitted && f.staff_name3.errors" class="invalid-feedback">
                              <div *ngIf="f.staff_name3.errors.required">Name is required</div>
                          </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="staff_designation3"><b>Designation</b></label>
                            <input class="form-control" formControlName="staff_designation3" type="text" required [ngClass]="{ 'is-invalid': contentSubmitted && f.staff_designation3.errors }"/>
                            <div *ngIf="contentSubmitted && f.staff_designation3.errors" class="invalid-feedback">
                              <div *ngIf="f.staff_designation3.errors.required">Designation is required</div>
                          </div>
                          </div>
                        </div>
                        <div class="form-group col-md-4 mt-1">
                          <label for="staff3Img"><b>Staff Image 3</b></label>
                          <input type="file" formControlName="staff3Img" (change)="imgStaff3Picker($event)"
                            #staff3ImgFile class="form-control-file" id="exampleFormControlFile1" [ngClass]="{ 'is-invalid': contentSubmitted && f.staff3Img.errors }">
                            <div *ngIf="contentSubmitted && f.staff3Img.errors" class="invalid-feedback">
                              <div *ngIf="f.staff3Img.errors.required">Staff Image is required</div>
                          </div>
                          <span class="text-danger" *ngIf="validStaff3File!=true">{{imgStaff3FileName}} </span>
                          </div>

                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="staff_name4"><b>Name</b></label>
                            <input class="form-control" formControlName="staff_name4" type="text" required [ngClass]="{ 'is-invalid': contentSubmitted && f.staff_name4.errors }"/>
                            <div *ngIf="contentSubmitted && f.staff_name4.errors" class="invalid-feedback">
                              <div *ngIf="f.staff_name4.errors.required">Name is required</div>
                          </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="staff_designation4"><b>Designation</b></label>
                            <input class="form-control" formControlName="staff_designation4" type="text" required [ngClass]="{ 'is-invalid': contentSubmitted && f.staff_designation4.errors }"/>
                            <div *ngIf="contentSubmitted && f.staff_designation4.errors" class="invalid-feedback">
                              <div *ngIf="f.staff_designation4.errors.required">Designation is required</div>
                          </div>
                          </div>
                        </div>
                        <div class="form-group col-md-4 mt-1">
                          <label for="staff4Img"><b>Staff Image 4</b></label>
                          <input type="file" formControlName="staff4Img" (change)="imgStaff4Picker($event)"
                            #staff4ImgFile class="form-control-file" id="exampleFormControlFile1" [ngClass]="{ 'is-invalid': contentSubmitted && f.staff4Img.errors }">
                            <div *ngIf="contentSubmitted && f.staff4Img.errors" class="invalid-feedback">
                              <div *ngIf="f.staff4Img.errors.required">Staff Image is required</div>
                          </div>
                          <span class="text-danger" *ngIf="validStaff4File!=true">{{imgStaff4FileName}} </span>
                          </div>

                      </div>




                    </div>

                    <!-- Staff Details End -->

                    <!-- Timings Section Start -->
                    <div class="content my-4">
                      <div class="titlebox">Timings Details</div>
                      <div class="row my-3">
                        <div class="col-md-4">
                          <div class="form-group mt-3">
                            <b class="timeLable">Monday to Friday</b><br>
                            <select class="form-control pb-2" formControlName="monday_friday" Placeholder="Mon to Fri"
                              required>
                              <option *ngFor="let data of mondayFriday" [value]="data.time">{{data.time}}</option>
                            </select>

                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group my-3">
                            <b class="timeLable">Saturday</b><br>
                            <select class="form-control pb-2" formControlName="saturday" Placeholder="Saturday" required>
                              <option *ngFor="let data of Saturday" [value]="data.time">{{data.time}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group mt-3">
                            <b class="timeLable">Sunday</b><br>
                            <select class="form-control pb-2" formControlName="sunday" Placeholder="Sunday" required>
                              <option *ngFor="let data of Sunday" [value]="data.time">{{data.time}}</option>
                            </select>

                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Timings Section End -->

                    <!-- Gallery Section Start -->
                    <div class="content my-4">
                      <div class="titlebox">6 Gallery Images</div>
                      <div class="row my-3">
                        <div class="form-group col-md-6 mt-4 ">
                          <label for="gallery1Img"><b>Gallery Image 1</b></label>
                          <input type="file" formControlName="gallery1Img" (change)="imgGallery1Picker($event)"
                            #gallery1Img class="form-control-file" id="exampleFormControlFile1" [ngClass]="{ 'is-invalid': contentSubmitted && f.gallery1Img.errors }">
                            <div *ngIf="contentSubmitted && f.gallery1Img.errors" class="invalid-feedback">
                              <div *ngIf="f.gallery1Img.errors.required">Gallery Image 1 is required</div>
                          </div>
                          <span class="text-danger" *ngIf="validGallery1File!=true">{{imgGallery1FileName}} </span>
                          </div>
                        <div class="form-group col-md-6 mt-4">
                          <label for="gallery1Img"><b>Gallery Image 2</b></label>
                          <input type="file" formControlName="gallery2Img" (change)="imgGallery2Picker($event)"
                            #gallery2Img class="form-control-file" id="exampleFormControlFile1" [ngClass]="{ 'is-invalid': contentSubmitted && f.gallery2Img.errors }">
                            <div *ngIf="contentSubmitted && f.gallery2Img.errors" class="invalid-feedback">
                              <div *ngIf="f.gallery2Img.errors.required">Gallery Image 2 is required</div>
                          </div>
                          <span class="text-danger" *ngIf="validGallery2File!=true">{{imgGallery2FileName}} </span>
                          </div>
                        <div class="form-group col-md-6 mt-4">
                          <label for="gallery1Img"><b>Gallery Image 3</b></label>
                          <input type="file" formControlName="gallery3Img" (change)="imgGallery3Picker($event)"
                            #gallery3Img class="form-control-file" id="exampleFormControlFile1" [ngClass]="{ 'is-invalid': contentSubmitted && f.gallery3Img.errors }">
                            <div *ngIf="contentSubmitted && f.gallery3Img.errors" class="invalid-feedback">
                              <div *ngIf="f.gallery3Img.errors.required">Gallery Image 3 is required</div>
                          </div>
                          <span class="text-danger" *ngIf="validGallery3File!=true">{{imgGallery3FileName}} </span>
                          </div>
                        <div class="form-group col-md-6 mt-4">
                          <label for="gallery1Img"><b>Gallery Image 4</b></label>
                          <input type="file" formControlName="gallery4Img" (change)="imgGallery4Picker($event)"
                            #gallery4Img class="form-control-file" id="exampleFormControlFile1" [ngClass]="{ 'is-invalid': contentSubmitted && f.gallery4Img.errors }">
                            <div *ngIf="contentSubmitted && f.gallery4Img.errors" class="invalid-feedback">
                              <div *ngIf="f.gallery4Img.errors.required">Gallery Image 4 is required</div>
                          </div>
                          <span class="text-danger" *ngIf="validGallery4File!=true">{{imgGallery4FileName}} </span>
                          </div>
                        <div class="form-group col-md-6 mt-4">
                          <label for="gallery1Img"><b>Gallery Image 5</b></label>
                          <input type="file" formControlName="gallery5Img" (change)="imgGallery5Picker($event)"
                            #gallery5Img class="form-control-file" id="exampleFormControlFile1" [ngClass]="{ 'is-invalid': contentSubmitted && f.gallery5Img.errors }">
                            <div *ngIf="contentSubmitted && f.gallery5Img.errors" class="invalid-feedback">
                              <div *ngIf="f.gallery5Img.errors.required">Gallery Image 5 is required</div>
                          </div>
                          <span class="text-danger" *ngIf="validGallery5File!=true">{{imgGallery5FileName}} </span>
                          </div>
                        <div class="form-group col-md-6 mt-4">
                          <label for="gallery1Img"><b>Gallery Image 6</b></label>
                          <input type="file" formControlName="gallery6Img" (change)="imgGallery6Picker($event)"
                            #gallery6Img class="form-control-file" id="exampleFormControlFile1" [ngClass]="{ 'is-invalid': contentSubmitted && f.gallery6Img.errors }">
                            <div *ngIf="contentSubmitted && f.gallery6Img.errors" class="invalid-feedback">
                              <div *ngIf="f.gallery6Img.errors.required">Gallery Image 6 is required</div>
                          </div>
                          <span class="text-danger" *ngIf="validGallery6File!=true">{{imgGallery6FileName}} </span>
                          </div>
                      </div>
                    </div>

                    <!-- Gallery Section End -->
                    <ul class="list-inline pull-right my-3">
                      <li class="mx-1"><a class="btn btn-info" href="#step1" (click)="step1Call()" data-toggle="tab"
                          aria-controls="step1" role="tab" aria-expanded="false">Back</a></li>
                      <li class="mx-1"><a class="btn btn-Next" (click)="step3Call()" data-toggle="tab"
                          aria-controls="step3" role="tab" aria-expanded="false">Continue</a></li>
                    </ul>
                  </div>
                  <!--Content Details End  -->

                  <!-- Account Details Start -->
                  <div class="tab-pane" [class.active]="step3" role="tabpanel" id="step3">
                    <h2 class="text-center">Account Details</h2>
                    <div class="container-padding">
                      <div class="content mt-5 pb-4">
                        <div class="titlebox">Account Details</div>
                        <div class="row my-5" style="row-gap: 15px;">
                          <div class="col-md-9">
                            <div class="form-group">
                              <label for="owner_name"><b>Card Holder Name</b></label>
                              <input class="form-control" formControlName="owner_name" type="text" required [ngClass]="{ 'is-invalid': accountSubmitted && f.owner_name.errors }"/>
                              <div *ngIf="accountSubmitted && f.owner_name.errors" class="invalid-feedback">
                                <div *ngIf="f.owner_name.errors.required">Card Holder Name is required</div>
                            </div>
                            </div>
                          </div>
  
                          <div class="col-md-3">
                            <div class="form-group">
                              <label  for="company_email"><b>CVV</b></label>
                              <input class="form-control" maxlength="4" minlength="3" formControlName="cvv" type="tel" required [ngClass]="{ 'is-invalid': accountSubmitted && f.cvv.errors }"/>
                              <div *ngIf="accountSubmitted && f.cvv.errors" class="invalid-feedback">
                                <div *ngIf="f.cvv.errors.required">CVV is required</div>
                                <div *ngIf="f.cvv.errors.minlength">CVV length is not valid</div>
                                <div *ngIf="f.cvv.errors.maxlength">CVV length is not valid</div>
                            </div>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label  for="company_landline"><b>Card #</b></label>
                              <input class="form-control" (keyup)="cc_format($event.target.value)" formControlName="card_number" [(ngModel)]="creditCardNumber" type="tel"  required [ngClass]="{ 'is-invalid': accountSubmitted && f.card_number.errors }"/>
                              <div *ngIf="accountSubmitted && f.card_number.errors" class="invalid-feedback">
                                <div *ngIf="f.card_number.errors.required">Card Number is required</div>
                                <div *ngIf="f.card_number.errors.minlength">Card Number length is not valid</div>
                                <div *ngIf="f.card_number.errors.maxlength">Card Number length is not valid</div>
                            </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="expiry_date"><b>Expiry Date</b></label>
                              <input class="form-control" maxlength='5' placeholder="MM/YY" type="text" (keyup)="formatString($event)" [(ngModel)]="expiryDate" formControlName="expiry_date" type="text" required [ngClass]="{ 'is-invalid': accountSubmitted && f.expiry_date.errors }"/>
                              <div *ngIf="accountSubmitted && f.expiry_date.errors" class="invalid-feedback">
                                <div *ngIf="f.expiry_date.errors.required">Expiry Date is required</div>
                                
                            </div>
                            </div>
                          </div>
                          
                          <div class="col-md-8">
                            <div class="d-flex justify-content-end mt-4">
                              <img class="mx-1" src="/assets/ravian-food/payment-form/6914119.png" style="height: 20px; width: 40px;"/>
                              <img class="mx-1" src="/assets/ravian-food/payment-form/visa.png" style="height: 20px; width: 40px;"/>
                              <!-- <img class="mx-1" src="/assets/ravian-food/payment-form/1200px-UnionPay_logo.svg.png" style="height: 20px; width: 30px;"/>
                              <img class="mx-1" src="/assets/ravian-food/payment-form/1LINK_logo-removebg-preview.png" style="height: 20px; width: 30px;"/> -->
                            </div>
                          </div>

                        </div>
                      </div>
                      <ul class="list-inline pull-right mt-5">
                        <li><a class="btn btn-info mx-1" href="#step2" (click)="step2Call()" data-toggle="tab"
                            aria-controls="step2" role="tab" aria-expanded="false">Back</a></li>
  
                        <li><a class="btn btn-Next mx-1" (click)='addCatering()'>Submit</a></li>
                      </ul>
                    </div>
                  
                  </div>
                 
                
                 
                  <!-- Account Details End -->



                  <div class="clearfix"></div>

                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>

</div>




<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="square-jelly-box"
>
  <p style="font-size: 20px; color: white">Saving...</p>
</ngx-spinner>