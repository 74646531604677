import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { CartService } from 'src/app/cart.service';
import * as $ from 'jquery';
import { ModalManager } from 'ngb-modal';

@Component({
  selector: 'app-cheetay-header',
  templateUrl: './cheetay-header.component.html',
  styleUrls: ['./cheetay-header.component.css'],

})
export class CheetayHeaderComponent implements OnInit {


  @ViewChild('myModal') myModal;
  public modalRef;

  public cartLengthHeader = this.cartService.getLengthItems();
  public isLogin : boolean = true;
  public isShowSearch: boolean = false;
  brandName: string;
  socialLinksData: any;
  facebookLink: any;
  instagramLink: any;
  linkedinLink: any;
  contactsData: any;
  headerPhone: any;
  topBarTitle: any;
  ContentsData: any;
  footerAddress: any;
  constructor(public router : Router, public cartService : CartService, public service : AppService, public modalService: ModalManager) { }

  ngOnInit(): void {
    
   this.getSocialLinks();
   this.getContactWeb();
   this.getContentWeb();
   
   setInterval(()=>{
    this.cartLengthHeader = this.cartService.getLengthItems();
    this.isLoginMethod();
   }, 1000)
  

   $(window).scroll(function(e){ 
    var $el = $('.mainNavBar'); 
    var isPositionFixed = ($el.css('position') == 'fixed');
    if ($(this).scrollTop() > 100 && !isPositionFixed){ 
      $el.css({'position': 'fixed', 'top': '0px' , 'width' : '100%'}); 
    }
    if ($(this).scrollTop() < 100 && isPositionFixed){
      $el.css({'position': 'static', 'top': '0px' , 'width' : '100%'}); 
    } 
  });

   
  $('.search-toggle').addClass('closed');

  $('.search-toggle .search-icon').click(function(e) {
    if ($('.search-toggle').hasClass('closed')) {
      $('.search-toggle').removeClass('closed').addClass('opened');
      $('.search-toggle, .search-container').addClass('opened');
      $('#search-terms').focus();
    } else {
      $('.search-toggle').removeClass('opened').addClass('closed');
      $('.search-toggle, .search-container').removeClass('opened');
    }
  });


 

  }





public isLoginMethod=()=>{
      
  if("tokenUser" in localStorage)
  {
    this.isLogin = false;
  }
  else
  {
    this.isLogin = true;
  }
   

}




public getSocialLinks=()=>{
   this.service.getSocialLinksWeb().subscribe((res:any)=>{
         this.socialLinksData = res.record;
         
         this.facebookLink = this.socialLinksData[0].link;
         this.instagramLink = this.socialLinksData[1].link;
         this.linkedinLink = this.socialLinksData[2].link;
   });
}




public getContentWeb=()=>{

  this.service.getContentWeb().subscribe((res :any)=>{
    this.ContentsData = res.record;
    this.topBarTitle =  this.ContentsData.filter(x => x.title === "Topbar_Heading");
    
  });

}






public getContactWeb=()=>{
    this.service.getContactWeb().subscribe((res :any)=>{
    this.contactsData = res.record;
    this.headerPhone =  this.contactsData.filter(x => x.title === "Header_Phone");
  });

}







  public showSearchBar=()=>{
    if(this.isShowSearch == false)
    {
      this.isShowSearch = true;
    }
    else
    {
      this.isShowSearch = false;
    }
 }




 public routingCall=(data)=>{
   this.router.navigate([data]);
 }

 
public logout=()=>{
  localStorage.removeItem('tokenUser');
  this.closeModal();
}



openModal() {

  this.modalRef = this.modalService.open(this.myModal, {
    size: "sm",
    modalClass: 'mymodal',
    hideCloseButton: true,
    centered: false,
    backdrop: true,
    animation: true,
    keyboard: false,
    closeOnOutsideClick: false,
    backdropClass: "modal-backdrop"
  })
}
closeModal() {
  this.modalService.close(this.modalRef);
  //or this.modalRef.close();
}






}
