import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { RouterModule } from '@angular/router';
import { from } from 'rxjs';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SharedModuleModule } from './shared-module/shared-module.module';
import { LoginComponent } from './login/login.component';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { CartComponent } from './cart/cart.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModalModule } from 'ngb-modal';
import { AddCateringComponent } from './add-catering/add-catering.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AddRestaurantComponent } from './add-restaurant/add-restaurant.component';
import { TokeninterceptorService } from './tokeninterceptor.service';
 
import { NgxSpinnerModule } from "ngx-spinner";
import { CheckoutLoginComponent } from './checkout-login/checkout-login.component';
import { ReviewOrderComponent } from './review-order/review-order.component';
import { TextMaskModule } from 'angular2-text-mask';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { SearchPageComponent } from './search-page/search-page.component';


 
 
 

const customNotifierOptions: NotifierOptions = {
  position: {
		horizontal: {
			position: 'middle',
			distance: 12
		},
		vertical: {
			position: 'top',
			distance: 12,
			gap: 10
		}
	},
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};


@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LoginComponent,
    ForgetPasswordComponent,
    CartComponent,
    AddCateringComponent,
    AddRestaurantComponent,
    CheckoutLoginComponent,
    ReviewOrderComponent,
    SearchPageComponent,
   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPaginationModule,
    RouterModule,
    ModalModule,
    SharedModuleModule,
    HttpClientModule,
    NotifierModule.withConfig(customNotifierOptions),
    HttpClientModule,
    NoopAnimationsModule,
    TextMaskModule,
    NgxSpinnerModule,
    GooglePlaceModule,
  
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide : HTTP_INTERCEPTORS , useClass : TokeninterceptorService , multi : true}],
    
  bootstrap: [AppComponent]
})

export class AppModule { }
