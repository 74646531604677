import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class UserLoginGuardService implements CanActivate{

  constructor(public service: AppService, public router: Router) { }

  canActivate(): boolean {
    
    if (this.service.user_varifying()) {

     return false;
    }
    else 
    {
      return true;
    }
  }
}
