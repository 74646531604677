<div class="container-fluid loginPageBg LeafBG">
    <notifier-container></notifier-container>
      <div class="container">
        <section class="mainForm">
          <div class="container-fluid mt-3">
            <div class="text-center">
              <img src="/assets/favicon.png" height="80px" /><br>
             
                <div class="progress mx-5" *ngIf="progressRestaurant">
                  <div class="progress-bar" [style.width]="progressRestaurant + '%'">{{progressRestaurant}} %</div>
                </div>
             
             
            </div>
            <div class="row mt-5 d-flex justify-content-center">
              <div class="col-md-10">
                <div class="wizard">
                  <div class="wizard-inner">
                    <div class="connecting-line"></div>
                    <ul class="nav nav-tabs" role="tablist">
                      <li role="presentation" [class.active]="step1">
                        <a><span class="round-tab">1 </span> <i>Step 1</i></a>
                      </li>
                      <li role="presentation" [class.active]="step2">
                        <a><span class="round-tab">2</span> <i>Step 2</i></a>
                      </li>
                      <li role="presentation" [class.active]="step3">
                        <a><span class="round-tab">3</span>
                          <i>Step 3</i></a>
                      </li>
                      <li role="presentation" [class.active]="step4">
                        <a><span class="round-tab">4</span>
                          <i>Step 4</i></a>
                      </li>
                    </ul>
                  </div>
                  <form [formGroup]="addRestaurantForm" class="login-box">
                    <div class="tab-content" id="main_form">
                      <!-- Company Details Start -->
                      <div class="tab-pane" [class.active]="step1" role="tabpanel" id="step1">
                        <h2 class="text-center">Restaurant Details</h2>
    
                        <div class="content mt-5 pb-4">
                          <div class="titlebox">Restaurant Details</div>
                          <div class="row mt-3" style="row-gap: 15px;">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label  for="restaurant_name"><b>Restaurant Name</b></label>
                                <input class="form-control" formControlName="restaurant_name" type="text" required [ngClass]="{ 'is-invalid': submitted && f.restaurant_name.errors }"/>
                                <div *ngIf="submitted && f.restaurant_name.errors" class="invalid-feedback">
                                  <div *ngIf="f.restaurant_name.errors.required">Restaurant Name is required</div>
                              </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label   for="restaurant_email"><b>Restaurant Email</b></label>
                                <input class="form-control" formControlName="restaurant_email" type="email" required [ngClass]="{ 'is-invalid': submitted && f.restaurant_email.errors }"/>
                                <div *ngIf="submitted && f.restaurant_email.errors" class="invalid-feedback">
                                  <div *ngIf="f.restaurant_email.errors.required">Restaurant Email is required</div>
                                  <div *ngIf="f.restaurant_email.errors.email">Restaurant Email is not Valid</div>
                              </div>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label   for="restaurant_landline"><b>Landline #</b></label>
                                <input class="form-control" formControlName="landline" type="tel" required [ngClass]="{ 'is-invalid': submitted && f.landline.errors }"/>
                                <div *ngIf="submitted && f.landline.errors" class="invalid-feedback">
                                  <div *ngIf="f.landline.errors.required">Landline is required</div>
                              </div>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label  for="restaurant_mobile"><b>Mobile #</b></label>
                                <input class="form-control" formControlName="mobile" type="tel" required [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }"/>
                                <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                  <div *ngIf="f.mobile.errors.required">Mobile # is required</div>
                              </div>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label for="restaurant0_fax"><b>Fax #</b></label>
                                <input class="form-control" formControlName="fax" type="tel" required [ngClass]="{ 'is-invalid': submitted && f.fax.errors }"/>
                                <div *ngIf="submitted && f.fax.errors" class="invalid-feedback">
                                  <div *ngIf="f.fax.errors.required">Fax # is required</div>
                              </div>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <label  for="restaurant_address"><b>Address</b></label>
                               <textarea class="form-control" formControlName="address" type="text" required [ngClass]="{ 'is-invalid': submitted && f.address.errors }"></textarea>
                               <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                <div *ngIf="f.address.errors.required">Address is required</div>
                            </div>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label for="restaurant_zip"><b>Zip Code</b></label>
                                <input class="form-control" formControlName="zipcode" type="text" required [ngClass]="{ 'is-invalid': submitted && f.zipcode.errors }"/>
                                <div *ngIf="submitted && f.zipcode.errors" class="invalid-feedback">
                                  <div *ngIf="f.zipcode.errors.required">zipcode is required</div>
                              </div>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label for="restaurant_city"><b>City</b></label>
                                <input class="form-control" formControlName="city" type="text" required [ngClass]="{ 'is-invalid': submitted && f.city.errors }"/>
                                <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                  <div *ngIf="f.city.errors.required">city is required</div>
                              </div>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label for="restaurant_state"><b>State</b></label>
                                <input class="form-control" formControlName="state" type="text" required [ngClass]="{ 'is-invalid': submitted && f.state.errors }"/>
                                <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                                  <div *ngIf="f.state.errors.required">state is required</div>
                              </div>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div id="map"></div>
                            </div>
    
    
                          </div>
                        </div>
    
                        <ul class="list-inline pull-right mt-5">
                          <li><a class="btn btn-Next"  (click)="step2Call()" data-toggle="tab"
                              aria-controls="step2" role="tab" aria-expanded="false">Continue to Next step</a></li>
                        </ul>
                      </div>
                      <!-- Company Details End -->
    
                      <!-- Content Details Start -->
                      <div class="tab-pane" [class.active]="step2" role="tabpanel" id="step2">
                        <h2 class="text-center mb-5">Web Content</h2>
                        <!-- Logo Start -->
                        <div class="content my-3">
                          <div class="titlebox">Logo Upload</div>
                          <div class="row">
                            <div class="form-group col-md-12 my-4">
                              <label for="logoImg" ><b>Logo Upload</b></label>
                              <input type="file" formControlName="logoImg" (change)="imgLogoPicker($event)" #logoFile
                                class="form-control-file" id="exampleFormControlFile1" [ngClass]="{ 'is-invalid': contentSubmitted && f.logoImg.errors }">
                                <div *ngIf="contentSubmitted && f.logoImg.errors" class="invalid-feedback">
                                  <div *ngIf="f.logoImg.errors.required">Logo is required</div>
                              </div>
                              <span class="text-danger" *ngIf="validLogoFile!=true">{{imgLogoFileName}} </span>
                            </div>
                          </div>
                        </div>
    
                        <!-- Logo End -->
    
                        <!-- Banner  Start -->
                        <div class="content my-3">
                          <div class="titlebox">Banner Image</div>
                          <div class="row">
                            <div class="form-group col-md-12 my-4">
                              <label for="bannerImg"><b>Banner Image Upload</b></label>
                              <input type="file" formControlName="bannerImg" (change)="imgBannerPicker($event)"
                                #bannerImgFile class="form-control-file" id="exampleFormControlFile1" [ngClass]="{ 'is-invalid': contentSubmitted && f.bannerImg.errors }">
                                <div *ngIf="contentSubmitted && f.bannerImg.errors" class="invalid-feedback">
                                  <div *ngIf="f.bannerImg.errors.required">Banner Image is required</div>
                              </div>
                              <span class="text-danger" *ngIf="validBannerFile!=true">{{imgBannerFileName}} </span>
                              </div>
 

                      </div>
                        </div>
                        <!-- Banner Us End -->
    
    
                        <!-- About us Start -->
                        <div class="content my-4">
                          <div class="titlebox">About us Content</div>
                          <div class="row my-2">
                            <div class="col-md-12 my-3">
                              <div class="form-group">
                                <label for="aboutus_content"><b>About Us Content</b></label>
                                <textarea class="form-control" formControlName="aboutus_content" type="text"
                                  required [ngClass]="{ 'is-invalid': contentSubmitted && f.aboutus_content.errors }"></textarea>
                                  <div *ngIf="contentSubmitted && f.aboutus_content.errors" class="invalid-feedback">
                                    <div *ngIf="f.aboutus_content.errors.required">About Us Content is required</div>
                                </div>
                              </div>
                            </div>
                           
                          </div>
                        </div>
    
                        <!-- About Us End -->


                             <!-- Chef Content Start -->
                             <div class="content my-4">
                                <div class="titlebox">Chef's Message</div>
                                <div class="row my-2">
                                  <div class="col-md-9 mb-2">
                                    <div class="form-group">
                                      <label for="aboutus_content"><b>Chef_s Message</b></label>
                                      <textarea class="form-control" formControlName="chef_content" type="text"
                                        required [ngClass]="{ 'is-invalid': contentSubmitted && f.chef_content.errors }"></textarea>
                                        <div *ngIf="contentSubmitted && f.chef_content.errors" class="invalid-feedback">
                                          <div *ngIf="f.chef_content.errors.required">Chef's Message is required</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-3 mb-2">
                                    <div class="form-group">
                                      <label  for="aboutus_content"><b>Chef_s Signature</b></label>
                                      <input class="form-control" formControlName="chef_signature" type="text"
                                        required [ngClass]="{ 'is-invalid': contentSubmitted && f.chef_signature.errors }"/>
                                        <div *ngIf="contentSubmitted && f.chef_signature.errors" class="invalid-feedback">
                                          <div *ngIf="f.chef_signature.errors.required">Chef's Signature is required</div>
                                      </div>
                                    </div>
                                  </div>
                                 
                                </div>
                              </div>
          
                              <!-- Chef Content End -->


    
                        <!-- Social Media Links Start -->
                        <div class="content my-4">
                          <div class="titlebox">Social Media Links</div>
                          <div class="row my-4">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="facebook_link"><b>Facebook Link</b></label>
                                <input class="form-control" formControlName="facebook_link" type="text" required [ngClass]="{ 'is-invalid': contentSubmitted && f.facebook_link.errors }"/>
                                <div *ngIf="contentSubmitted && f.facebook_link.errors" class="invalid-feedback">
                                  <div *ngIf="f.facebook_link.errors.required">Facebook Link is required</div>
                              </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label  for="instagram_link"><b>Instagram Link</b></label>
                                <input class="form-control" formControlName="instagram_link" type="text" required [ngClass]="{ 'is-invalid': contentSubmitted && f.instagram_link.errors }"/>
                                <div *ngIf="contentSubmitted && f.instagram_link.errors" class="invalid-feedback">
                                  <div *ngIf="f.instagram_link.errors.required">Instagram Link is required</div>
                              </div>
                              </div>
                            </div>
                          </div>
                        </div>
    
                        <!-- Social Media Links End -->
    
    
                        <!-- Contact Links Start -->
                        <div class="content my-4">
                          <div class="titlebox">Contact Details</div>
                          <div class="row my-4">
                            <div class="col-md-4">
                              <div class="form-group">
                                <label  for="landline_content"><b>Landline #</b></label>
                                <input class="form-control" formControlName="landline_content" type="tel" required [ngClass]="{ 'is-invalid': contentSubmitted && f.landline_content.errors }"/>
                                <div *ngIf="contentSubmitted && f.landline_content.errors" class="invalid-feedback">
                                  <div *ngIf="f.landline_content.errors.required">Landline # is required</div>
                              </div>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label   for="mobile_content"><b>Mobile #</b></label>
                                <input class="form-control" formControlName="mobile_content" type="tel" required [ngClass]="{ 'is-invalid': contentSubmitted && f.mobile_content.errors }"/>
                                <div *ngIf="contentSubmitted && f.mobile_content.errors" class="invalid-feedback">
                                  <div *ngIf="f.mobile_content.errors.required">Mobile # is required</div>
                              </div>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label   for="email_content"><b>Email</b></label>
                                <input class="form-control" formControlName="email_content" type="email" required [ngClass]="{ 'is-invalid': contentSubmitted && f.email_content.errors }"/>
                                <div *ngIf="contentSubmitted && f.email_content.errors" class="invalid-feedback">
                                  <div *ngIf="f.email_content.errors.required">Email is required</div>
                                  <div *ngIf="f.email_content.errors.email">Email is not valid</div>
                              </div>
                              </div>
    
    
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <label   for="address_content"><b>Address</b></label>
                                <textarea class="form-control" formControlName="address_content" type="text"
                                  required [ngClass]="{ 'is-invalid': contentSubmitted && f.address_content.errors }"></textarea>
                                  <div *ngIf="contentSubmitted && f.address_content.errors" class="invalid-feedback">
                                    <div *ngIf="f.address_content.errors.required">Address is required</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
    
                        <!-- Contact Links End -->
    
                    
    
                        <!-- Special Dishes Details Start -->
                        <!-- <div class="content my-4">
                          <div class="titlebox">5 Special Dishes</div>
    
                          <div class="row my-2 pb-3">
    
                            <div class="col-md-4">
                              <div class="signin__field">
                                <input class="signin__input" formControlName="special_dish1" type="text" required />
                                <label class="signin__label" for="staff_name1">Dish Name</label>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="signin__field">
                                <input class="signin__input" formControlName="specialDishPrice1" type="text" required />
                                <label class="signin__label" for="staff_designation1">Price</label>
                              </div>
                            </div>
                            <div class="form-group col-md-4 mt-4">
                              <input type="file" formControlName="specialDish1Img" (change)="imgDish1Picker($event)"
                                #specialDish1ImgFile class="form-control-file" id="exampleFormControlFile1">
                            </div>
    
                            <div class="col-md-4">
                              <div class="signin__field">
                                <input class="signin__input" formControlName="special_dish2" type="text" required />
                                <label class="signin__label" for="staff_name2">Dish Name</label>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="signin__field">
                                <input class="signin__input" formControlName="specialDishPrice2" type="text" required />
                                <label class="signin__label" for="staff_designation2">Price</label>
                              </div>
                            </div>
                            <div class="form-group col-md-4 mt-4">
                              <input type="file" formControlName="specialDish1Img" (change)="imgDish2Picker($event)"
                                #specialDish2ImgFile class="form-control-file" id="exampleFormControlFile1">
                            </div>
    
                            <div class="col-md-4">
                              <div class="signin__field">
                                <input class="signin__input" formControlName="special_dish3" type="text" required />
                                <label class="signin__label" for="staff_name3">Dish Name</label>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="signin__field">
                                <input class="signin__input" formControlName="specialDishPrice3" type="text" required />
                                <label class="signin__label" for="staff_designation3">Price</label>
                              </div>
                            </div>
                            <div class="form-group col-md-4 mt-4">
                              <input type="file" formControlName="specialDish3Img" (change)="imgDish3Picker($event)"
                                #specialDish3ImgFile class="form-control-file" id="exampleFormControlFile1">
                            </div>
    
                            <div class="col-md-4">
                              <div class="signin__field">
                                <input class="signin__input" formControlName="special_dish4" type="text" required />
                                <label class="signin__label" for="staff_name4">Dish Name</label>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="signin__field">
                                <input class="signin__input" formControlName="specialDishPrice4" type="text" required />
                                <label class="signin__label" for="staff_designation4">Price</label>
                              </div>
                            </div>
                            <div class="form-group col-md-4 mt-4">
                              <input type="file" formControlName="specialDish4Img" (change)="imgDish4Picker($event)"
                                #specialDish3ImgFile class="form-control-file" id="exampleFormControlFile1">
                            </div>
    

                            <div class="col-md-4">
                                <div class="signin__field">
                                  <input class="signin__input" formControlName="special_dish5" type="text" required />
                                  <label class="signin__label" for="staff_name4">Dish Name</label>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="signin__field">
                                  <input class="signin__input" formControlName="specialDishPrice5" type="text" required />
                                  <label class="signin__label" for="staff_designation4">Price</label>
                                </div>
                              </div>
                              <div class="form-group col-md-4 mt-4">
                                <input type="file" formControlName="specialDish5Img" (change)="imgDish5Picker($event)"
                                  #specialDish3ImgFile class="form-control-file" id="exampleFormControlFile1">
                              </div>



                          </div>
    
    
    
    
                        </div>
     -->
                        <!-- Special Dishes Details End -->
    
                      <!-- Featured Foods Start -->
                      <div class="content my-4">
                        <div class="titlebox">3 Featured Dishes</div>
  
                        <div class="row my-2 my-4">
  
                          <div class="col-md-4">
                            <div class="form-group">
                              <label  for="staff_name1"><b>Dish Name</b></label>
                              <input class="form-control" formControlName="feature_dish1" type="text" required [ngClass]="{ 'is-invalid': contentSubmitted && f.feature_dish1.errors }"/>
                              <div *ngIf="contentSubmitted && f.feature_dish1.errors" class="invalid-feedback">
                                <div *ngIf="f.feature_dish1.errors.required">Dish Name is required</div>
                            </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label   for="staff_designation1"><b>Details</b></label>
                                <textarea class="form-control" formControlName="featureDishPrice1" type="text" required [ngClass]="{ 'is-invalid': contentSubmitted && f.featureDishPrice1.errors }"></textarea>
                                <div *ngIf="contentSubmitted && f.featureDishPrice1.errors" class="invalid-feedback">
                                  <div *ngIf="f.featureDishPrice1.errors.required">Dish Details is required</div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group col-md-4 mt-1">
                            <label   for="staff_designation1"><b>Image</b></label>
                            <input type="file" formControlName="featureDish1Img" (change)="imgFeature1Picker($event)"
                              #featureDish1ImgFile class="form-control-file" id="exampleFormControlFile1" [ngClass]="{ 'is-invalid': contentSubmitted && f.featureDish1Img.errors }">
                              <div *ngIf="contentSubmitted && f.featureDish1Img.errors" class="invalid-feedback">
                                <div *ngIf="f.featureDish1Img.errors.required">Dish Image is required</div>
                            </div>
                            <span class="text-danger" *ngIf="validFeature1File!=true">{{imgFeature1FileName}} </span>
                            </div>
  
                          <div class="col-md-4">
                            <div class="form-group">
                              <label  for="staff_name1"><b>Dish Name</b></label>
                              <input class="form-control" formControlName="feature_dish2" type="text" required [ngClass]="{ 'is-invalid': contentSubmitted && f.feature_dish2.errors }"/>
                              <div *ngIf="contentSubmitted && f.feature_dish2.errors" class="invalid-feedback">
                                <div *ngIf="f.feature_dish2.errors.required">Dish Name is required</div>
                            </div>
                            
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label   for="staff_designation1"><b>Details</b></label>
                                <textarea class="form-control" formControlName="featureDishPrice2" type="text" required [ngClass]="{ 'is-invalid': contentSubmitted && f.featureDishPrice2.errors }"></textarea>
                                <div *ngIf="contentSubmitted && f.featureDishPrice2.errors" class="invalid-feedback">
                                  <div *ngIf="f.featureDishPrice2.errors.required">Dish Details is required</div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group col-md-4 mt-1">
                            <label   for="staff_designation1"><b>Image</b></label>
                            <input type="file" formControlName="featureDish2Img" (change)="imgFeature2Picker($event)"
                              #featureDish2ImgFile class="form-control-file" id="exampleFormControlFile1" [ngClass]="{ 'is-invalid': contentSubmitted && f.featureDish2Img.errors }">
                              <div *ngIf="contentSubmitted && f.featureDish2Img.errors" class="invalid-feedback">
                                <div *ngIf="f.featureDish2Img.errors.required">Dish Image is required</div>
                            </div>
                            <span class="text-danger" *ngIf="validFeature2File!=true">{{imgFeature2FileName}} </span>
                            </div>
  
                          <div class="col-md-4">
                            <div class="form-group">
                              <label  for="staff_name1"><b>Dish Name</b></label>
                              <input class="form-control" formControlName="feature_dish3" type="text" required [ngClass]="{ 'is-invalid': contentSubmitted && f.feature_dish3.errors }"/>
                              <div *ngIf="contentSubmitted && f.feature_dish3.errors" class="invalid-feedback">
                                <div *ngIf="f.feature_dish3.errors.required">Dish Name is required</div>
                            </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label   for="staff_designation1"><b>Details</b></label>
                                <textarea class="form-control" formControlName="featureDishPrice3" type="text" required [ngClass]="{ 'is-invalid': contentSubmitted && f.featureDishPrice3.errors }"></textarea>
                                <div *ngIf="contentSubmitted && f.featureDishPrice3.errors" class="invalid-feedback">
                                  <div *ngIf="f.featureDishPrice3.errors.required">Dish Details is required</div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group col-md-4 mt-1">
                            <label   for="staff_designation1"><b>Image</b></label>
                            <input type="file" formControlName="featureDish3Img" (change)="imgFeature3Picker($event)"
                              #featureDish3ImgFile class="form-control-file" id="exampleFormControlFile1" [ngClass]="{ 'is-invalid': contentSubmitted && f.featureDish3Img.errors }">
                              <div *ngIf="contentSubmitted && f.featureDish3Img.errors" class="invalid-feedback">
                                <div *ngIf="f.featureDish3Img.errors.required">Dish Image is required</div>
                            </div>
                            <span class="text-danger" *ngIf="validFeature3File!=true">{{imgFeature3FileName}} </span>
                            </div>
  
                       
   


                        </div>
  
  
  
  
                      </div>
  
                   <!-- Featured Foods End -->
    
                        <!-- Gallery Section End -->
                        <ul class="list-inline pull-right my-3">
                          <li class="mx-1"><a class="btn btn-info" href="#step1" (click)="step1Call()" data-toggle="tab"
                              aria-controls="step1" role="tab" aria-expanded="false">Back</a></li>
                          <li class="mx-1"><a class="btn btn-Next" (click)="step3Call()" data-toggle="tab"
                              aria-controls="step3" role="tab" aria-expanded="false">Continue</a></li>
                        </ul>
                      </div>
                      <!--Content Details End  -->
    
                      <!-- Account Details Start -->
                      <div class="tab-pane" [class.active]="step3" role="tabpanel" id="step3">
                        <h2 class="text-center">Account Details</h2>
                        <div class="container-padding">
                          <div class="content mt-5 pb-4">
                            <div class="titlebox">Account Details</div>
                            <div class="row py-5" style="row-gap: 25px;">
                              <div class="col-md-9">
                                <div class="form-group">
                                  <label for="owner_name"><b>Card Holder Name</b></label>
                                  <input class="form-control" formControlName="owner_name" type="text" required [ngClass]="{ 'is-invalid': accountSubmitted && f.owner_name.errors }"/>
                                  <div *ngIf="accountSubmitted && f.owner_name.errors" class="invalid-feedback">
                                    <div *ngIf="f.owner_name.errors.required">Card Holder Name is required</div>
                                </div>
                                </div>
                              </div>
      
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label  for="company_email"><b>CVV</b></label>
                                  <input class="form-control" maxlength="4" minlength="3" formControlName="cvv" type="tel" required [ngClass]="{ 'is-invalid': accountSubmitted && f.cvv.errors }"/>
                                  <div *ngIf="accountSubmitted && f.cvv.errors" class="invalid-feedback">
                                    <div *ngIf="f.cvv.errors.required">CVV is required</div>
                                    <div *ngIf="f.cvv.errors.minlength">CVV length is not valid</div>
                                    <div *ngIf="f.cvv.errors.maxlength">CVV length is not valid</div>
                                </div>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label  for="company_landline"><b>Card #</b></label>
                                  <input class="form-control" (keyup)="cc_format($event.target.value)" formControlName="card_number" [(ngModel)]="creditCardNumber" type="tel"  required [ngClass]="{ 'is-invalid': accountSubmitted && f.card_number.errors }"/>
                                  <div *ngIf="accountSubmitted && f.card_number.errors" class="invalid-feedback">
                                    <div *ngIf="f.card_number.errors.required">Card Number is required</div>
                                    <div *ngIf="f.card_number.errors.minlength">Card Number length is not valid</div>
                                    <div *ngIf="f.card_number.errors.maxlength">Card Number length is not valid</div>
                                </div>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="expiry_date"><b>Expiry Date</b></label>
                                  <input class="form-control" placeholder="MM/YY" maxlength='5' type="text" (keyup)="formatString($event)" [(ngModel)]="expiryDate" formControlName="expiry_date" type="text" required [ngClass]="{ 'is-invalid': accountSubmitted && f.expiry_date.errors }"/>
                                  <div *ngIf="accountSubmitted && f.expiry_date.errors" class="invalid-feedback">
                                    <div *ngIf="f.expiry_date.errors.required">Expiry Date is required</div>
                                    
                                </div>
                                </div>
                              </div>
                              
                              <div class="col-md-8">
                                <div class="d-flex justify-content-end mt-4">
                                  <img class="mx-1" src="/assets/ravian-food/payment-form/6914119.png" style="height: 20px; width: 40px;"/>
                                  <img class="mx-1" src="/assets/ravian-food/payment-form/visa.png" style="height: 20px; width: 40px;"/>
                                  <!-- <img class="mx-1" src="/assets/ravian-food/payment-form/1200px-UnionPay_logo.svg.png" style="height: 20px; width: 30px;"/>
                                  <img class="mx-1" src="/assets/ravian-food/payment-form/1LINK_logo-removebg-preview.png" style="height: 20px; width: 30px;"/> -->
                                </div>
                              </div>
    
                            </div>
                          </div>
                          <ul class="list-inline pull-right mt-5">
                            <li><a class="btn btn-info mx-1" href="#step2" (click)="step2Call()" data-toggle="tab"
                                aria-controls="step2" role="tab" aria-expanded="false">Back</a></li>
      
                            <li><a class="btn btn-Next mx-1" (click)='addRestaurant()'>Continue</a></li>
                          </ul>
                        </div>
                      
                      </div>
                      <!-- Promo Video Start-->
                      <div class="tab-pane" [class.active]="step4" role="tabpanel" id="step4">
                        <h2 class="text-center">Promo Video Upload</h2>
                        <div class="container-padding">
                          <div class="content my-5 pb-4">
                            <div class="titlebox">Upload</div>
                            <div class="row py-5" style="row-gap: 25px;">
                                  <div class="form-group col-md-12 my-5">
                                    <form [formGroup]="promoVideoForm">

                                    <input type="file" formControlName="promoVideo" (change)="promoVideoPicker($event)"
                                      #promoVideoFile class="form-control-file" id="exampleFormControlFile1">
                                      <span class="text-danger" *ngIf="validVideoPromo!=true">{{promoVideoName}} </span>
                                    </form>  
                                    <div class="progress" *ngIf="progress">
                                      <div class="progress-bar" [style.width]="progress + '%'">{{progress}} %</div>
                                    </div>
                                </div>
                            </div>
                          </div>
                          <ul class="list-inline pull-right mt-5">
                            <li><button type="button" class="btn btn-Next mx-1"  [disabled]="!validVideoPromo" (click)="addVideo()">Upload</button></li>
                          </ul>
                        </div>
                      
                      </div>
                     <!-- Promo Video End -->
                     
                      <!-- Account Details End -->
    
    
    
                      <div class="clearfix"></div>
    
                    </div>
    
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
    
      </div>
    
    </div>








    <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="square-jelly-box"
>
  <p style="font-size: 20px; color: white">Saving...</p>
</ngx-spinner>