<app-cheetay-header></app-cheetay-header>
<notifier-container></notifier-container>


<!--------Cart Content Start---------->
<div class="container-fluid overflow-hidden LeafBG bgCart">

  <div class="container" data-aos="slide-down">
    <div class="wrapper wrapper-content animated fadeInRight">
      <div class="row">
        <div class="col-md-12 col-lg-9 col-sm-12 col-xs-12">
          <div class="ibox">
            <!----------Cart Item Header Start----------->
            <div class="ibox-title">
              <span class="pull-right">(<strong>{{cartLength}}</strong>) items</span>
              <h2 class="main_title">Items in your cart</h2>
            </div>
            <!---------------Cart Item Header End----------->

            <!---------------Cart Item Start---------------->
            <div class="ibox-content" *ngFor="let data of cartItemData;">
              <div class="row">
                <div class="col-md-6 d-flex justify-content-around">
                  <img class="menuItemImg" [src]="imagesUrl+data.product_image" />
                  <div class="desc">
                    <h4>
                      <a class="text-navy">
                        {{data.product_name}}
                      </a>
                    </h4>
                    <dl class="small m-b-none" *ngFor="let data1 of data.features">
                      <li style="margin-bottom: -15px;">{{data1}}</li>
                    </dl>
                    <div class="m-t-sm mt-4">
                      <a (click)="removeCart(data)" class="btn btn-dark btn-sm text-light"><i class="fa fa-trash"></i>
                        Remove item</a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 topMargin d-flex justify-content-between">
                  <div>
                    ${{data.product_price}} -
                    <s class="small text-muted">${{data.product_price+10}}</s>
                  </div>
                  <div>
                    <input type="number" width="90" class="form-control" min="1" [value]="data.quantity"
                      [(ngModel)]="data.quantity" (ngModelChange)="onValueChange($event , data)">
                  </div>
                  <div>
                    <h4>
                      <span>{{data.calcPrice}} CHF</span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <!---------------Cart Item End---------------->


            <!----------Cart Item Footer Start----------->
            <div class="ibox-content">
              <button class="btn btns pull-right" (click)="checkOut()"><i class="fa fa fa-shopping-cart"></i>
                Checkout</button>
              <button routerLink="/menu-cheetah" class="btn btn-white"><i class="fa fa-arrow-left"></i> Go to Menu
                Page</button>
            </div>
            <!----------Cart Item Footer End----------->
          </div>
        </div>
        <div class="col-md-12 col-lg-3 col-sm-12 col-xs-12">
          <div class="ibox">
            <div class="ibox-title">
              <h5 class="main_title text-center">Cart Summary</h5>
            </div>
            <div class="ibox-content text-center">
              <span>
                Total
              </span>
              <h2 class="font-bold">
                {{totalamount}} <span>CHF</span> 
              </h2>
              <span class="text-muted small">
                *Sales Tax Applicable
              </span>
              <hr>
              <div class="m-t-sm">
                <div class="btn-group">
                  <a class="btn btns btn-sm" (click)="checkOut()"><i class="fa fa-shopping-cart"></i> Checkout</a>
                  <a (click)="openModal()" class="btn btn-dark btn-sm text-light mx-2"><i class="fas fa-trash"></i>
                    Clear Cart</a>
                </div>
              </div>
            </div>
          </div>

          <div class="ibox text-center">
            <div class="ibox-title">
              <h5 class="main_title">Support</h5>
            </div>
            <div class="ibox-content text-center">
              <h5><i class="fa fa-phone-alt"></i> +41 241 241 242</h5>
              <p>
                Please contact with us if you have any questions. We are available 24/7.
              </p>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>

</div>
<!--------Cart Content End---------->






<!-- Footer -->
<app-cheetay-footer></app-cheetay-footer>
<!-- Footer -->




<!-- =================================-->
<!---------Remove All Modal start--------->
<!-- =================================-->
<modal #myModal style="text-align: -webkit-center;">
  <modal-header>
    <div class="row">
      <div class="col topBar">
        <div class="container">
          <b class="topBarText">Are you Sure?</b>
        </div>

      </div>
    </div>
  </modal-header>

  <modal-content>
    <div class="d-flex justify-content-around">
      <button type="button" class="btn btn-danger btn-sm" (click)="removeAllCart()">Yes <span><i class="fa fa-check"
            aria-hidden="true"></i></span></button>&nbsp;
      <button type="button" class="btn btn-success btn-sm" (click)="closeModal()">Cancel <i class="fa fa-times"
          aria-hidden="true"></i>
      </button>
    </div>
  </modal-content>

</modal>
<!-- =================================-->
<!---------Remove All Modal End--------->
<!-- =================================-->







<!-- =================================-->
<!---------Login Modal start------->
<!-- =================================-->
<modal #myModalLogin>
  <modal-header>
    <div class="row">
      <div class="col topBar">
        <div class="container">
          <b class="topBarText">Are you Sure?</b>
        </div>

      </div>
    </div>
  </modal-header>

  <modal-content>
    <div class="row">
      <div class="col-md-12">

      </div>
    </div>
  </modal-content>

  <modal-footer>
    <div class="d-flex justify-content-around">
      <button type="button" class="btn btn-danger btn-sm">Login <span><i class="fa fa-check"
            aria-hidden="true"></i></span></button>&nbsp;
      <button type="button" class="btn btn-success btn-sm" (click)="closeModalLogin()">Cancel <i class="fa fa-times"
          aria-hidden="true"></i>
      </button>
    </div>
  </modal-footer>

</modal>
<!-- =================================-->
<!---------Login Modal End--------->
<!-- =================================-->