import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalManager } from 'ngb-modal';
import { environment } from 'src/environments/environment';
import { CartService } from '../cart.service';
import * as AOS from 'aos';
import { NotifierService } from 'angular-notifier';
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

  public imagesUrl = environment.BaseUrlImages;

  @ViewChild('myModal') myModal;
  public modalRef;
  @ViewChild('myModalLogin') myModalLogin;
  public modalRefLogin;

  public cartLength: number = 0;
  public cartItemData = this.cartService.getItems();
  public cartItemNum: number = 1;
  public itemValue: number = 1;
  public notifier: NotifierService;
  public calcValue: number = 0;
  public totalamount: number = 0;
  public emptyAllCart = [];
  public signupForm: FormGroup;
  cartItems: any;
  constructor(public cartService: CartService, public modalService: ModalManager,
    public route: Router, public fb: FormBuilder, public notifierService: NotifierService,) {

    this.notifier = notifierService;
  }

  ngOnInit(): void {

    AOS.init({
      duration: 1000,
    });

    this.getTotal();
    this.cartLength = this.cartService.getLengthItems();

  }






  public removeCart = (data) => {
    let cart_items = this.cartService.getItems();
    for (let i = 0; i <= cart_items.length; i++)
      if (cart_items[i].id === data.id) {
        cart_items.splice(i, 1);
        localStorage["products"] = JSON.stringify(cart_items);

        this.cartService.items = cart_items;

        this.cartItemData = this.cartService.getItems();
        this.cartLength = this.cartService.getLengthItems();
        this.getTotal();
        this.cartService.getLengthItems();
      }
  }




  public onValueChange = (data: number, data1) => {
    let a = data1.product_price;
    let b: number = +a;
    this.cartItemNum = data;
    data1.calcPrice = this.cartItemNum * b;
    data1.quantity = this.cartItemNum;
    localStorage.setItem("products", JSON.stringify(this.cartItemData));
    this.getTotal();
  }






  public getTotal = () => {
    this.totalamount = 0;
    for (var i = 0; i < this.cartItemData.length; i++) {
      if (this.cartItemData[i].calcPrice) {
        this.totalamount = this.totalamount + this.cartItemData[i].calcPrice;
      }

    }
    return this.totalamount;
  }






  public removeAllCart = () => {
    this.cartService.removeAllCart();
    this.cartItemData = this.cartService.getItems();
    this.cartLength = this.cartService.getLengthItems();
    this.getTotal();
    this.closeModal();
  }







  public addQuantity = (data) => {
    data++;
  }







  public minusQuantity = (data) => {

    if (data != 1) {
      data--;
    }
    else {
      return;
    }

  }





  openModal() {

    this.modalRef = this.modalService.open(this.myModal, {
      size: "sm",
      modalClass: 'mymodal',
      hideCloseButton: true,
      centered: false,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: false,
      backdropClass: "modal-backdrop"
    })
  }
  closeModal() {
    this.modalService.close(this.modalRef);
    //or this.modalRef.close();
  }




  openModalLogin() {

    this.modalRefLogin = this.modalService.open(this.myModalLogin, {
      size: "lg",
      modalClass: 'mymodal',
      hideCloseButton: true,
      centered: false,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: false,
      backdropClass: "modal-backdrop"
    })
  }
  closeModalLogin() {
    this.modalService.close(this.modalRefLogin);
    //or this.modalRef.close();
  }







  public checkOut = () => {

    if (this.cartLength !== 0) {
      if ("tokenUser" in localStorage) {
        this.route.navigate(['review-order']);
      }
      else {
        this.route.navigate(['checkout-login']);
      }
    }
    else {
      this.notifier.notify("error", "Please Add items to your cart!");
    }



  }

















}
